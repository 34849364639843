import React, { ReactNode, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

import { GET_CATEGORIES_WITH_LOCALE } from "../gql";
import { Category } from "../types";
import { loadFromCache, saveToCache } from "../util/cache";

export type CategoriesDictionary = { [id: string]: Category };

type CategoriesContextValue = {
  loading: boolean;
  error: Error | undefined;
  categories: Category[];
  categoriesMap: CategoriesDictionary;
};

const CategoriesContext = React.createContext<CategoriesContextValue>({
  loading: false,
  error: undefined,
  categories: [],
  categoriesMap: {},
});

export const CategoriesProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();

  const cacheKey = `categories_${i18n.language}`;

  // Attempt to load cached values from localStorage
  const { cachedCategories } = useMemo(
    () => {
      return { cachedCategories: { [i18n.language]: loadFromCache(cacheKey) } };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  const { loading, error, data } = useQuery(GET_CATEGORIES_WITH_LOCALE, {
    variables: { locale: i18n.language },
  });


  const categories: Category[] = useMemo(
    () =>
      // Use value from cache (if available) until the newly loaded value is available
      data?.categories?.length
        ? Array.from(data?.categories ?? [])
        : cachedCategories[i18n.language]?.content ?? [],
    [cachedCategories, data?.categories, i18n.language]
  );

  // Save downloaded data to the cache
  useEffect(() => {
    if (!categories.length) return;
    saveToCache(cacheKey, 0, categories);
  }, [categories, cacheKey]);

  const value = useMemo(
    () => ({
      loading,
      error,
      categories: categories.sort((a, b) =>
        a.displayOrder < b.displayOrder
          ? -1
          : a.displayOrder > b.displayOrder
          ? 1
          : 0
      ),
      categoriesMap: Object.freeze(
        Object.fromEntries(
          categories.map((category) => [category.id, category])
        )
      ),
    }),
    [loading, error, categories]
  );

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategories = () => useContext(CategoriesContext);
