import styled, { keyframes } from "styled-components";

export const MainHeader = styled.h2`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: bold;
`;

export type PlaceholderRectProps = {
  width: number | string;
  height: number | string;
  bg?: boolean;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number | string;
  fontSize?: number;
};

const placeHolderShimmer = keyframes`
    0% { background-position: -1000px 0 }
    100% { background-position: 1000px 0 }
`;

export const PlaceholderRect = styled.div`
  border-radius: 1px;
  background-color: ${({ bg = true }: PlaceholderRectProps) =>
    `${bg ? "#ddd" : "transparent"}`};
  width: ${({ width }: PlaceholderRectProps) =>
    `${width}${typeof width === "number" ? "px" : ""}`};
  max-width: 100%;
  height: ${({ height }: PlaceholderRectProps) =>
    `${height}${typeof height === "number" ? "px" : ""}`};
  margin: ${({ mt = 0, mr = 0, mb = 0, ml = 0 }: PlaceholderRectProps) =>
    `${mt}px ${mr}px ${mb}px ${ml}${typeof ml === "number" ? "px" : ""}`};

  animation-name: ${placeHolderShimmer};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right bottom, #ddd 10%, #eee 15%, #ddd 30%);
  background-size: 2000px 2000px;
`;

export const Button = styled.button`
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgb(88 140 213),
    rgba(20, 99, 200, 1) 80%
  );
  padding: 4px 16px;
  cursor: pointer;
  color: #eee;
  font-weight: 600;
  background-color: ${({ theme }) => theme.color.primary};
  border: none;
  height: 32px;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);
  ${({ disabled }) =>
    disabled
      ? `background: linear-gradient(180deg, rgba(88, 140, 213, 0.7), rgba(20,99,200,0.7) 80% );
         background-color: white;
         cursor: default`
      : ""}
`;

export const CardStyle = `
  padding: 32px;
  border-radius: 3px;
  background: white;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);
`;

export const SubHeader = styled.h2`
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin: ${({ t = 0, b = 4 }: { t?: number; b?: number }) =>
    `${t}px 0 ${b}px 0`};
`;

export const Spacer = ({ size = 16 }: { size?: number }) => (
  <div style={{ marginTop: size, width: "100%" }} />
);

export const ClearFix = styled.div`
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
`;
