import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useScrollTopOnMount } from "../../common/components/Scroll";
import { usePageMeta } from "../../common/providers/metadata";
import { sendEvent } from "../../common/util/analytics";
import ContactForm, { EntryArea } from "../components/elements/ContactForm";
import SeoLangRelTag from "../components/SeoLangRelTag";
import { Button, Spacer, SubHeader } from "../components/styled";

const ContactPage: FC = () => {
  const { i18n, t } = useTranslation("contact");
  const { setPageMeta } = usePageMeta();

  const [showForm, setShowForm] = useState(false);

  useEffect(
    () => setPageMeta({ title: t("title") }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );
  useScrollTopOnMount();

  return (
    <div>
      <SeoLangRelTag />
      <section>
        <SubHeader children={t("how_order_h")} />
        <Paragraph children={t("how_order_p1")} />
        <Paragraph children={t("how_order_p2")} />
        <Paragraph dangerouslySetInnerHTML={{ __html: t("how_order_p3") }} />
        <Paragraph children={t("how_order_p4")} />
      </section>
      <section>
        <SubHeader t={24} children={t("where_to_find")} />
        <Address>
          <AddressField>
            <AddressLabel children={t("address_l")} />
            <AddressValue>
              {t("shop_name")}
              <AddressLink text={t("address")} />
            </AddressValue>
          </AddressField>
        </Address>
        <AddressField>
          <AddressLabel children={t("opening_hours_l")} />
          <AddressValue>
            <OpeningHours children={t("opening_hours")} />
          </AddressValue>
        </AddressField>
        <Paragraph
          dangerouslySetInnerHTML={{ __html: t("opening_hours_note") }}
        />
      </section>
      <section>
        <SubHeader t={24}>{t("contact_us")}</SubHeader>
        <Address>
          <dl>
            <AddressField>
              <AddressLabel children={t("phone_l")} />
              <AddressValue>
                <a href="tel:+302103251405">210-3251405</a>
                <a href="tel:+302106232373">210-6232373</a>
              </AddressValue>
            </AddressField>

            <AddressField>
              <AddressLabel children={t("mobile_l")} />
              <AddressValue>
                <a href="tel:+306982715347">6982715347</a>
              </AddressValue>
            </AddressField>

            <AddressField>
              <AddressLabel>E-mail</AddressLabel>
              <AddressValue>
                <a href="mailto:retsasbooks@gmail.com">retsasbooks@gmail.com</a>
              </AddressValue>
            </AddressField>
          </dl>
        </Address>

        <Spacer size={20} />

        {showForm ? null : (
          <Button
            onClick={() => {
              setShowForm(true);
              sendEvent("showContactForm", { locale: i18n.language });
            }}
            style={{ minWidth: 280 }}
          >
            {t("send_message_action")}
          </Button>
        )}
        <EntryArea active={showForm}>
          <SubHeader>{t("send_message_action")}</SubHeader>
          <ContactForm maxWidth={750} />
        </EntryArea>
      </section>
    </div>
  );
};

export default ContactPage;

const Paragraph = styled.p`
  font-size: 16px;
  text-align: justify;
  > strong {
    white-space: nowrap;
  }
`;

const Address = styled.address`
  margin-top: 8px;
  white-space: pre;
  font-size: 16px;
  text-align: justify;
  > strong {
    white-space: nowrap;
  }
`;

const AddressField = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-size: 16px;
  flex-wrap: wrap;
`;

const AddressLabel = styled.dt`
  display: flex;
  min-width: 120px;
  margin-right: 0
  text-align: right;
`;

const AddressValue = styled.dd`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const OpeningHours = styled.span`
  font-weight: 900;
`;

const googleMapsLink =
  "https://www.google.com/maps/place/%CE%92%CE%B9%CE%B2%CE%BB%CE%B9%CE%BF%CF%80%CF%89%CE%BB%CE%B5%CE%AF%CE%BF+%CE%A1%CE%AD%CF%84%CF%83%CE%B1%CF%82/@37.9766783,23.7208646,17z/data=!3m1!4b1!4m13!1m7!3m6!1s0x14a1bd109e0c7007:0x686d78c2ba8c0d36!2zzpLOuc6yzrvOuc6_z4DPic67zrXOr86_IM6hzq3PhM-DzrHPgg!8m2!3d37.9766783!4d23.7230533!10e1!3m4!1s0x14a1bd109e0c7007:0x686d78c2ba8c0d36!8m2!3d37.9766783!4d23.7230533";

const AddressLink: FC<{ text: string }> = ({ text }) => {
  return (
    <strong itemProp="address">
      <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </strong>
  );
};
