import { FC, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { sendEvent } from "../../../common/util/analytics";

type FullPageErrorMessageProps = {
  messageKey?: string;
};

const createMetaNode = () => {
  // In theory this should stop search engines from indexes error pages
  const result = document.createElement("meta");
  result.setAttribute("name", "robots");
  result.setAttribute("content", "noindex");
  return result;
};

const noIndexMetaNode = createMetaNode();

const FullPageErrorMessage: FC<FullPageErrorMessageProps> = ({
  messageKey = "unknown_error",
}) => {
  const { t } = useTranslation("errors");

  useEffect(() => {
    document.head.appendChild(noIndexMetaNode);
    sendEvent("pageError", { messageKey });
    return () => noIndexMetaNode.remove();
  }, [messageKey]);

  return <ErrorMessage>{t(messageKey)}</ErrorMessage>;
};

export default FullPageErrorMessage;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 24px;
  margin: 64px 16px;
`;
