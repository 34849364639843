import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

import {
  MAX_X_SMALL,
  MAX_SMALL,
  useResponsive,
} from "../../../common/providers/responsive";
import { sendEvent } from "../../../common/util/analytics";
import { OnClickHandler } from "../../../common/types";
import FullPageErrorMessage from "../../components/elements/FullPageErrorMessage";
import CategoriesList from "../elements/CategoriesList";
import PageHeader from "../header/PageHeader";
import SearchBox from "../header/SearchBox";
import { Spacer } from "../styled";
import PageFooter, { ContentWrapper, FooterPush } from "./PageFooter";

const PageLayout: FC = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { xSmall } = useResponsive();
  let location = useLocation();

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  return (
    <>
      <PageWrapper>
        <ContentWrapper>
          <PageHeader
            onClickMenuBtn={() => setDrawerOpen((drawerOpen) => !drawerOpen)}
          />
          <Container>
            <PageBody>
              {xSmall ? (
                <SideDrawer
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                />
              ) : (
                <SideCol>
                  <CategoriesList />
                </SideCol>
              )}
              <MainCol>
                <MainAreaErrorBoundary children={children} />
              </MainCol>
            </PageBody>
          </Container>
          <FooterPush />
        </ContentWrapper>
        <PageFooter />
      </PageWrapper>
    </>
  );
};

export default PageLayout;

const SideDrawer: FC<{ open: boolean; onClose: OnClickHandler }> = ({
  open,
  onClose,
}) => {
  return (
    <>
      <SideDrawerContainer visible={open}>
        <SearchBox fullWidth />
        <Spacer size={20} />
        <CategoriesList />
      </SideDrawerContainer>
      <SideDrawerOverlay visible={open} onClick={onClose} />
    </>
  );
};

const SideDrawerContainer = styled.aside`
  z-index: 45;
  min-width: 264px;
  max-width: 264px;
  background: #fafafa;
  padding: 124px 16px 8px 16px;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 75%);
  margin-right: 16px;
  position: fixed;

  top: 0;
  bottom: 0;
  height: auto;
  overflow-y: auto;
  transition: left ease 150ms;
  ${({ visible }: { visible: boolean }) =>
    visible ? "left: 0;" : "left: -100%;"}
`;

const SideDrawerOverlay = styled.div`
  z-index: 40;
  position: fixed;
  background: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  transition: opacity ease 150ms;
  ${({ visible }: { visible: boolean }) =>
    visible ? "opacity: 0.6;" : "opacity: 0; pointer-events: none;"}
`;

const PageWrapper = styled.div`
  height: 100vh;
  font-family: ${({ theme }) => theme.fontFamily};
  @media ${MAX_SMALL} {
    > div > .MuiContainer-root {
      padding: 0 16px;
    }
  }
`;

const PageBody = styled.div`
  display: flex;
`;

const SideCol = styled.aside`
  min-width: 300px;
  max-width: 300px;
  @media ${MAX_SMALL} {
    min-width: 260px;
    max-width: 260px;
  }
`;

const MainCol = styled.main`
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 100%; // Avoids IE11 bug
  margin-left: 32px;
  @media ${MAX_SMALL} {
    margin-left: 16px;
  }
  @media ${MAX_X_SMALL} {
    margin-left: 0;
  }
`;

class MainAreaErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.log("Main page error", error, errorInfo);
    sendEvent("mainAreaError", { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      <FullPageErrorMessage />;
    }
    return this.props.children;
  }
}
