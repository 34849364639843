export function saveToCache(key: string, version: number, content: any) {
  if (!content) return;
  window.localStorage.setItem(key, JSON.stringify({ version, content }));
}

export function loadFromCache(key: string) {
  let value: any = {};
  try {
    value = JSON.parse(window.localStorage.getItem(key) ?? "{}");
  } catch {}
  if (value.content) {
    return { content: value.content, version: value.version ?? 0 };
  }
  return { content: null, version: -1 };
}
