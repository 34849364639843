import React, { ReactNode, useContext, useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";

import { AUTHORS_QUERY, KEYWORDS_QUERY } from "../gql";
import { Author } from "../types";
import { loadFromCache, saveToCache } from "../util/cache";

type ResourceValue = {
  loading: boolean;
  error: Error | undefined;
  get: () => void;
};

type AuthorsKeywordsContextValue = {
  keywords: ResourceValue & { keywords: string[] };
  authors: ResourceValue & {
    authors: Author[];
    authorNames: { [nativeName: string]: string };
  };
};

const AuthorsKeywordsContext = React.createContext<AuthorsKeywordsContextValue>(
  {
    authors: {
      loading: false,
      error: undefined,
      authors: [],
      authorNames: {},
      get() {},
    },
    keywords: { loading: false, error: undefined, keywords: [], get() {} },
  }
);

function getIfNeeded(getter: (variables?: any) => void, data: any): () => void {
  return () => !data && getter();
}

export const AuthorsKeywordsProvider = ({
  timeout = 8000,
  children,
}: {
  timeout?: number;
  children: ReactNode;
}) => {
  // Attempt to load cached values from localStorage
  const { cachedAuthors, cachedKeywords } = useMemo(
    () => ({
      cachedAuthors: loadFromCache("authors"),
      cachedKeywords: loadFromCache("keywords"),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Setup lazy requests
  const [
    getAuthors,
    { loading: authorsLoading, error: authorsError, data: authorsData },
  ] = useLazyQuery(AUTHORS_QUERY, {
    variables: { version: cachedAuthors.version },
  });
  const [
    getKeywords,
    { loading: keywordsLoading, error: keywordsError, data: keywordsData },
  ] = useLazyQuery(KEYWORDS_QUERY, {
    variables: { version: cachedKeywords.version },
  });

  // Save downloaded data to the cache
  useEffect(() => {
    if (!authorsData) return;
    saveToCache("authors", authorsData.version, authorsData.authors);
  }, [authorsData]);
  useEffect(() => {
    if (!keywordsData) return;
    saveToCache("keywords", keywordsData.version, keywordsData.keywords);
  }, [keywordsData]);

  // Create loaders the only fire if they've never succeeded before
  const getAuthorsIfNeeded = getIfNeeded(getAuthors, authorsData);
  const getKeywordsIfNeeded = getIfNeeded(getKeywords, keywordsData);

  // Resolve values from either cache or the backend response
  const authors = cachedAuthors.content ?? authorsData?.authors;
  const keywords = cachedKeywords.content ?? keywordsData?.keywords;

  // Request all resources after a delay if not yet requested
  useEffect(() => {
    setTimeout(() => {
      !authors && !authorsLoading && !authorsError && getAuthorsIfNeeded();
      !keywords && !keywordsLoading && !keywordsError && getKeywordsIfNeeded();
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Recompute the final context value whenever something changes
  const value = useMemo(
    () => ({
      authors: {
        loading: authorsLoading,
        error: authorsError,
        authors: authors ?? [],
        authorNames: Object.fromEntries(
          (authors ?? []).map(({ nativeName, greekName }: Author) => [
            nativeName,
            greekName,
          ])
        ),
        get: getAuthorsIfNeeded,
      },
      keywords: {
        loading: keywordsLoading,
        error: keywordsError,
        keywords: keywords ?? [],
        get: getKeywordsIfNeeded,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      authorsLoading,
      authorsError,
      authors,
      keywordsLoading,
      keywordsError,
      keywords,
    ]
  );

  return (
    <AuthorsKeywordsContext.Provider value={value}>
      {children}
    </AuthorsKeywordsContext.Provider>
  );
};

export const useAuthorsKeywords = ({
  authors,
  keywords,
}: {
  authors?: boolean;
  keywords?: boolean;
} = {}): AuthorsKeywordsContextValue => {
  const value = useContext(AuthorsKeywordsContext);

  useEffect(() => {
    if (authors) value.authors.get();
    if (keywords) value.keywords.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
};
