import { FC, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const ScrollToTopOnMount: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export const useScrollTopOnMount = () =>
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

export const useScrollTopOnUpdate = (key: any, active: boolean = true) => {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!active) return;
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      window.scrollTo(0, 0);
    }
  }, [key, active]);
};
