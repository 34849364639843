import { createGlobalStyle } from "styled-components";
// import AvenirBookWoff from "./Avenir-Book.woff";
// import AvenirBookWoff2 from "./Avenir-Book.woff2";
import AvenirRegularWoff from "./Avenir-Regular.woff";
import AvenirRegularWoff2 from "./Avenir-Regular.woff2";
// import AvenirLightWoff from "./Avenir-Light.woff";
// import AvenirLightWoff2 from "./Avenir-Light.woff2";
import UbuntuRegularWoff2 from "./ubuntu-regular.woff2";

export default createGlobalStyle`
  /*
  @font-face {
      font-family: "Avenir book";
      src: url("${/*AvenirBookWoff2*/ ""}") format('woff2'),
           url("${/*AvenirBookWoff*/ ""}") format('woff');
  }
  */
  @font-face {
      font-family: "Avenir regular";
      src: url("${AvenirRegularWoff2}") format('woff2'),
           url("${AvenirRegularWoff}") format('woff');
  }
  /*
  @font-face {
      font-family: "Avenir light";
      src: url("${/*AvenirLightWoff2*/ ""}") format('woff2'),
           url("${/*AvenirLightWoff*/ ""}") format('woff');
  }
  */
  @font-face {
      font-family: "Ubuntu";
      src: url("${UbuntuRegularWoff2}") format('woff2');
  }
`;
