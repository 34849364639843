import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const PageFooter: FC = () => {
  return (
    <FooterContainer>
      <CopyrightMessage />
    </FooterContainer>
  );
};

export default PageFooter;

const FooterContainer = styled.div`
  height: 40px;
  width: 100%;
  background-color: #333;
  background: linear-gradient(180deg, #2f2f2f, #3f3f3f 100%);
  color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  min-height: 100%;
  margin-bottom: -40px;
`;

export const FooterPush = styled.div`
  height: 60px;
`;

const CopyrightMessage: FC = () => {
  const { t } = useTranslation("footer");
  return <span>{`© 2011-${new Date().getFullYear()} ${t("proprietor")}`}</span>;
};
