import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";

export const usePageIndex = () => {
  const location = useLocation();

  const query = useMemo(() => qs.parse(location.search), [location.search]);

  let pageNumber = 0;
  if (query.page) {
    pageNumber =
      typeof query.page === "object"
        ? parseInt(query.page[0], 10) ?? 1
        : (pageNumber = parseInt(query.page, 10) ?? 1);
  }

  return {
    pageIndex: pageNumber - 1,
    getUpdatedLink(newPageIndex: number) {
      return `${location.pathname}?${qs.stringify({
        ...query,
        page: newPageIndex + 1,
      })}`;
    },
  };
};
