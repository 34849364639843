import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import * as React from "react";
import { render } from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import App from "./App";
import "./common/i18n";

render(
  <>
    <CssBaseline />
    <App />
  </>,
  document.getElementById("root")
);
