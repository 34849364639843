import { FC, ReactNode, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";

import { LeftChevron } from "../../common/components/icons";
import { useBook } from "../../common/hooks/use-books";
import { sendEvent } from "../../common/util/analytics";
import {
  CategoriesDictionary,
  useCategories,
} from "../../common/providers/categories";
import { Author, Category, formatAuthor } from "../../common/types";
import BookImageViewer from "../../common/components/BookImageViewer";
import NullValuePlaceholder from "../../common/components/NullValuePlaceholder";
import { useScrollTopOnMount } from "../../common/components/Scroll";
import { usePageMeta } from "../../common/providers/metadata";
import {
  useResponsive,
  MAX_X_SMALL,
  MAX_SMALL,
  MAX_MEDIUM,
} from "../../common/providers/responsive";
import routes from "../../routes";
import ContactForm, { EntryArea } from "../components/elements/ContactForm";
import FullPageErrorMessage from "../components/elements/FullPageErrorMessage";
import SeoLangRelTag from "../components/SeoLangRelTag";
import {
  Button,
  CardStyle,
  PlaceholderRect,
  Spacer,
  SubHeader,
} from "../components/styled";

type BookPageProps = {
  bookId: string;
  theme: any;
};

const BookPage: FC<BookPageProps> = ({ bookId, theme }) => {
  const { i18n, t } = useTranslation("book_page");
  const location = useLocation();
  const { ready, book, error } = useBook(bookId);
  const { categoriesMap, loading } = useCategories();
  const [showForm, setShowForm] = useState(false);
  const { setPageMeta } = usePageMeta();

  const { getResponsiveValue } = useResponsive();
  const imageWidth = getResponsiveValue({ xl: 280, l: 255, s: 230, xs: 250 });

  useScrollTopOnMount();

  useEffect(() => {
    if (book?.title) {
      return setPageMeta({ title: book.title });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book, i18n.language]);

  const returnAction: string | undefined = (location?.state as any)
    ?.returnAction;

  if ((!ready && !book && !error) || loading) {
    return <BookPageContentPlaceholder returnAction={returnAction} />;
  }

  if (error) {
    error && console.error("Error loading book", error);
    return <FullPageErrorMessage messageKey="unknown_error" />;
  }
  if (!book) {
    return <FullPageErrorMessage messageKey="no_book" />;
  }

  const { fontFamily1, fontFamily } = theme;

  return (
    <>
      <SeoLangRelTag />
      <BackButton returnAction={returnAction} />
      <BookCard>
        <BookTitle>{book?.title}</BookTitle>
        <BookCardContent>
          <BookImageViewer images={book?.images ?? []} width={imageWidth} />
          <BookDetailsWrapper>
            <BookDetails>
              <BookDetail label={t("author")}>
                <AuthorItems authors={book?.authors} />
              </BookDetail>
              <BookDetail
                label={t("price")}
                value={`€${book?.price}`}
                fontFamily={fontFamily1}
                bold
              />
              <BookDetail
                label={t("published_year")}
                value={book?.year}
                fontFamily={fontFamily1}
              />
              <BookDetail label={t("publisher")} value={book?.publisher} />
              <BookDetail label={t("published_place")} value={book?.place} />
              <BookDetail
                label={t("illustration")}
                value={book?.illustration}
              />
              <BookDetail label={t("translator")} value={book?.translator} />
              <BookDetail label={t("edition")} value={book?.edition} />
              <BookDetail label={t("volumes")} value={book?.volumes} />
              <BookDetail
                label={t("pages")}
                value={book?.pages}
                fontFamily={fontFamily1}
              />
              <BookDetail label={t("book_number")} value={book?.bookNumber} />
              <BookDetail label={t("dedication")} value={book?.dedication} />
              <BookDetail label={t("description")} value={book?.description} />
              <BookDetail label={t("category")} fontFamily={fontFamily}>
                <CategoryItems
                  categoryIds={book?.categoryIds}
                  categories={categoriesMap}
                />
              </BookDetail>
              <BookDetail
                label=""
                value={
                  <BookAvailability available={book?.available}>
                    {book?.available ? t("available") : t("out_of_stock")}
                  </BookAvailability>
                }
                fontFamily={fontFamily}
              />
            </BookDetails>
            {book && !showForm && (
              <Button
                onClick={() => {
                  setShowForm(true);
                  sendEvent("showEnquireForm", {
                    bookId: book?.id,
                    locale: i18n.language,
                  });
                }}
              >
                {t("enquire_action")}
              </Button>
            )}
          </BookDetailsWrapper>
        </BookCardContent>
        <EntryArea active={book && showForm}>
          <Spacer size={16} />
          <SubHeader>{t("book_interest_header")}</SubHeader>
          <ContactForm book={book} />
        </EntryArea>
      </BookCard>
    </>
  );
};

const responsivePlaceholderPamams = {
  s: {
    header: "75%",
    img: [275, 360],
    fields: [
      [75, 250],
      [60, 35],
      [80, 55],
      [90, 95],
      [68, 200],
      [50, 45],
      [55, 300],
      [0, 65],
    ],
    multiline: true,
  },
  xs: {
    header: "100%",
    img: ["265px", 340],
    fields: [
      [75, 250],
      [60, 35],
      [80, 55],
      [90, 95],
      [68, 200],
      [50, 45],
      [55, 260],
      [0, 65],
    ],
    multiline: false,
  },
};

const BookPageContentPlaceholder: FC<{ returnAction: string | undefined }> = ({
  returnAction,
}) => {
  const { getResponsiveValue } = useResponsive();
  const params = getResponsiveValue(responsivePlaceholderPamams);

  return (
    <>
      <BackButton returnAction={returnAction} />
      <BookCard>
        <PlaceholderRect width={params.header} height={28} mb={16} />
        <BookCardContent>
          <div
            style={{
              marginBottom: 8,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PlaceholderRect
              width={params.img[0]}
              height={params.img[1]}
              mr={params.multiline ? 16 : 0}
            />
          </div>
          <BookDetailsWrapper>
            <div>
              {params.fields.map(([l, r], i) => (
                <BookDetailPlaceholder
                  key={i}
                  l={l}
                  r={r}
                  multiline={params.multiline}
                />
              ))}
            </div>
          </BookDetailsWrapper>
        </BookCardContent>
      </BookCard>
    </>
  );
};

const BookDetailPlaceholder = ({
  l,
  r,
  multiline,
}: {
  l: number;
  r: number;
  multiline: boolean;
}) => (
  <BookDetailPlaceholderContainer>
    <div>
      <PlaceholderRect width={l} height={20} ml={multiline ? 90 - l : 0} />
    </div>
    <div>
      <PlaceholderRect width={r} height={20} ml={multiline ? 24 : 0} />
    </div>
  </BookDetailPlaceholderContainer>
);

const BookDetailPlaceholderContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 12px;
  margin-bottom: 20px;
  @media ${MAX_X_SMALL} {
    flex-direction: column;
  }
`;

const BackButton: FC<{ returnAction: string | undefined }> = ({
  returnAction,
}) => {
  const history = useHistory();
  const { t } = useTranslation("elements");
  if (!returnAction) {
    return null;
  }
  return (
    <BackButtonContainer onClick={() => history.goBack()} tabIndex={0}>
      <LeftChevron style={{ width: 20, height: 20 }} />
      {returnAction[1].replace("{}", t(returnAction[0]))}
    </BackButtonContainer>
  );
};
const BackButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  min-height: 20px;
  margin-bottom: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const AuthorItems: FC<{ authors?: Author[] }> = ({ authors }) => (
  <div>
    {authors?.length ? (
      authors?.map((author, i) => (
        <dd key={i}>
          <Link
            to={routes.to("author-books", {
              nativeName: author.nativeName,
              ...(author.greekName ? { greekName: author.greekName } : {}),
            })}
          >
            {formatAuthor(author)}
          </Link>
        </dd>
      ))
    ) : (
      <NullValuePlaceholder />
    )}
  </div>
);

const applySpecialCategoryFormattingRule = (category: Category | undefined) => {
  if (category?.superCategory === category?.subCategory) {
    // this is stupid
    return `${category?.superCategory}`;
  }
  return `${category?.superCategory} – ${category?.subCategory}`;
};

const CategoryItems: FC<{
  categoryIds?: string[];
  categories: CategoriesDictionary;
}> = ({ categoryIds, categories }) => {
  return (
    <div>
      {categoryIds?.map((categoryId, idx) => {
        const category = categories[categoryId];
        return (
          <dd key={idx}>
            <Link to={routes.to("category-books", { categoryId })}>
              {applySpecialCategoryFormattingRule(category)}
            </Link>
          </dd>
        );
      })}
    </div>
  );
};

const BookDetail: FC<{
  label: string;
  children?: ReactNode;
  value?: ReactNode;
  fontFamily?: string;
  bold?: boolean;
}> = ({ label, value, children, fontFamily, bold }) => {
  if (!value && !children) return null;
  return (
    <BookDetailContainer>
      <dt>{label}</dt>
      {value ? (
        <dd style={{ fontFamily, fontWeight: bold ? "bold" : "inherit" }}>
          {value}
        </dd>
      ) : undefined}
      {children}
    </BookDetailContainer>
  );
};

// @ts-ignore
export default withTheme(BookPage);

const BookCard = styled.article`
  ${CardStyle}
`;

const BookTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 22px;
  font-family: ${({ theme }) => theme.fontFamily2};
  line-height: 28px;
`;

const BookCardContent = styled.div`
  display: flex;
  @media ${MAX_SMALL} {
    flex-direction: column;
  }
`;

const BookDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 32px;
  @media ${MAX_X_SMALL} {
    margin-left: 0px;
  }
`;

const BookDetails = styled.dl`
  margin: 12px 0;
  > div {
    display: flex;
    align-items: top;
    margin-bottom: 20px;
  }
  & dt {
    width: 150px;
    text-align: right;
    padding-right: 16px;
    flex-shrink: 0;
    @media ${MAX_MEDIUM} {
      width: auto;
      text-align: left;
    }
  }
  & dd {
    margin-inline-start: 0;
    font-family: ${({ theme }) => theme.fontFamily2};
    word-break: break-word;
  }
`;

const BookDetailContainer = styled.div`
  display: flex;
  @media ${MAX_MEDIUM} {
    flex-direction: column;
  }
`;

const BookAvailability = styled.span`
  font-weight: bold;
  color: ${({ available, theme }: { available: any; theme: any }) =>
    available ? theme.color.positive : theme.color.negative};
`;
