import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client/react";
import Website from "./website/Website";
import { clientManager } from "./common/graphql/client";
import { AuthorsKeywordsProvider } from "./common/providers/authorskeywords";
import { CategoriesProvider } from "./common/providers/categories";
import { FeaturedBooksProvider } from "./common/providers/featured";
import { PageMetaProvider } from "./common/providers/metadata";
import routes from "./routes";

const App = () => (
  <BrowserRouter>
    <ApolloProvider client={clientManager.client}>
      <CategoriesProvider>
        <FeaturedBooksProvider>
          <AuthorsKeywordsProvider>
            <PageMetaProvider>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route
                    path={routes.get("admin")}
                    component={lazy(() => import("./admin/AdminBackend"))}
                  />
                  <Route path={routes.get("root")} render={() => <Website />} />
                </Switch>
              </Suspense>
            </PageMetaProvider>
          </AuthorsKeywordsProvider>
        </FeaturedBooksProvider>
      </CategoriesProvider>
    </ApolloProvider>
  </BrowserRouter>
);

export default App;
