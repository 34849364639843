import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useWebp } from "../../common/hooks/use-webp";
import { useScrollTopOnMount } from "../../common/components/Scroll";
import { ClearFix } from "../components/styled";
import { MAX_X_SMALL, MAX_LARGE } from "../../common/providers/responsive";

import wpportraitUrl from "../images/dimitris.webp";
import portraitUrl from "../images/dimitris.jpg";

import FeaturedBooks from "../components/elements/FeaturedBooks";
import SeoLangRelTag from "../components/SeoLangRelTag";

const HomePage: FC = () => {
  const { t } = useTranslation("homepage");
  useScrollTopOnMount();

  const webpSupport = useWebp();

  return (
    <>
      <SeoLangRelTag />
      <IntroSection>
        <Portrait
          src={webpSupport.alpha ? wpportraitUrl : portraitUrl}
          alt={t("portrait_alt")}
          title={t("portrait_alt")}
        />
        <IntroParagraphs
          dangerouslySetInnerHTML={{
            __html: t("welcome_p1") + t("welcome_p2") + t("welcome_p3"),
          }}
        />
      </IntroSection>
      <ClearFix />
      <FeaturedBooks />
    </>
  );
};

export default HomePage;

const IntroSection = styled.section``;

const IntroParagraphs = styled.div`
  font-size: 16px;
  text-align: justify;
  > p {
    margin: 0 0 8px 0;
  }
  & a {
    border-bottom: 1px dashed #999;
    font-weight: normal;
    color: inherit;
    text-decoration: none;
  }
  & .info-box {
    clear: both;
    padding: 12px;
    margin-top: 16px;
    border: 1px solid #237deb99;
    box-shadow: 0 0 3px 1px #4680d199;
    border-radius: 5px;
  }
`;

const Portrait = styled.img`
  margin: 0 0 16px 24px;
  float: right;
  width: 50%;
  max-width: 450px;
  border-radius: 5px;
  align-self: flex-start;
  width: 45%;
  @media ${MAX_LARGE} {
    width: 50%;
  }
  @media ${MAX_X_SMALL} {
    margin: 0 0 4px 16px;
  }
`;
