import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import BookImage from "../../../common/components/BookImage";
import NullValuePlaceholder from "../../../common/components/NullValuePlaceholder";
import { useScrollTopOnUpdate } from "../../../common/components/Scroll";
import { useBooks, BooksFilters } from "../../../common/hooks/use-books";
import { usePageIndex } from "../../../common/hooks/use-page-index";
import { useResponsive } from "../../../common/providers/responsive";
import { Book } from "../../../common/types";
import { PageState } from "../../../common/util/PaginatedResult";
import routes from "../../../routes";
import FullPageErrorMessage from "../../components/elements/FullPageErrorMessage";
import { PlaceholderRect, Spacer } from "../styled";
import BookListingPagination from "./BookListingPagination";

type BookListingProps = {
  filter: BooksFilters;
  returnAction?: [string, string];
  errorKey?: string;
  renderOptions?: () => ReactNode;
};

const BookListing: FC<BookListingProps> = ({
  filter,
  returnAction,
  errorKey,
  renderOptions,
}) => {
  const { pageIndex } = usePageIndex();
  const { books, error } = useBooks(filter, pageIndex);
  const { t } = useTranslation("elements");

  const { getResponsiveValue } = useResponsive();
  const paginationRadius = getResponsiveValue({
    xl: 5,
    l: 4,
    m: 3,
    s: 1,
    xs: 0,
  });

  const [{ contents: pageItems, pending }, setPageState] = useState<
    PageState<Book>
  >(() => books.getPage());

  useEffect(() => {
    setPageState(books.getPage());
    books.onUpdate(setPageState);
  }, [books]);

  useScrollTopOnUpdate(books.activePageIndex);

  if (error) {
    console.error("Failed to load book listing:", error);
    return <FullPageErrorMessage messageKey="unknown_error" />;
  }

  if (pending && !pageItems.length) {
    return <BookListingPlaceholder />;
  }

  if (!pageItems.length) {
    return <FullPageErrorMessage messageKey={errorKey} />;
  }

  return (
    <div>
      <HeaderRow>
        {renderOptions?.()}
        <div style={{ flexGrow: 1 }} />
        {!books.isPending() && (
          <Summary>
            {books.numItmes} {t("list_summary_books")}
          </Summary>
        )}
      </HeaderRow>
      <BookListingItems>
        {pageItems.map((book) => (
          <BookListingRow
            key={book.id}
            book={book}
            returnAction={returnAction}
            t={t}
          />
        ))}
      </BookListingItems>
      <Spacer size={20} />
      <BookListingPagination books={books} radius={paginationRadius} />
    </div>
  );
};

export default BookListing;

const HeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  margin-top: -4px;
  margin-bottom: 4px;
`;

const Summary = styled.div`
  color: #666;
  margin-right: 12px;
`;

const BookListingItems = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

type BookListingRowProps = {
  book: Book;
  returnAction?: [string, string];
  t: (key: string) => string;
};

const imagePlaceholderProps = {
  width: 105,
  height: 150,
  ml: 15,
  mr: 15,
};

const BookListingRow: FC<BookListingRowProps> = ({ book, returnAction, t }) => {
  return (
    <Link
      to={{
        pathname: routes.to("book-details", {
          bookId: book.id,
        }),
        state: { returnAction },
      }}
      style={{
        textDecoration: "none",
        color: "inherit",
        display: "block",
        width: "100%",
      }}
    >
      <BookListingRowLi>
        <section>
          <BookImageWrapper>
            <BookImage
              image={book.images?.[0]}
              alt={book.title}
              placeholder={imagePlaceholderProps}
            />
          </BookImageWrapper>
          <BookDetailCol>
            <h3>{book.title}</h3>
            <BookDetailLine>
              {book.authorship ? book.authorship : <NullValuePlaceholder />}
            </BookDetailLine>
            <BookDetailLine>{book.description}</BookDetailLine>
            <div style={{ flexGrow: 1 }} />
            <BookDetailLine>
              <BookPrice>€{book.price}</BookPrice>
              <div style={{ flexGrow: 1 }} />
              {/* @ts-ignore */}
              <BookAvailability available={book.available}>
                {book.available ? t("available") : t("out_of_stock")}
              </BookAvailability>
            </BookDetailLine>
          </BookDetailCol>
        </section>
      </BookListingRowLi>
    </Link>
  );
};

const BookListingPlaceholder: FC = () => {
  const { xSmall } = useResponsive();

  return (
    <div>
      <PlaceholderRect width={xSmall ? "95%" : "100%"} height={20} bg={false} />
      <BookListingItems>
        <BookListingItemPlaceholder xSmall={xSmall} />
        <BookListingItemPlaceholder xSmall={xSmall} />
        <BookListingItemPlaceholder xSmall={xSmall} />
        <BookListingItemPlaceholder xSmall={xSmall} />
        <BookListingItemPlaceholder xSmall={xSmall} />
        <Spacer size={20} />
      </BookListingItems>
    </div>
  );
};

const BookListingItemPlaceholder: FC<{ xSmall: boolean }> = ({ xSmall }) => {
  return (
    <BookListingRowLi style={{ width: "100%" }}>
      <section>
        <PlaceholderRect {...imagePlaceholderProps} />
        <BookDetailCol>
          <PlaceholderRect width="90%" height={24} />
          <PlaceholderRect width={xSmall ? 180 : 250} height={22} mt={16} />
          <PlaceholderRect width={40} height={22} mt={8} />
          <PlaceholderRect width={32} height={22} mt={35} />
        </BookDetailCol>
      </section>
    </BookListingRowLi>
  );
};

const BookListingRowLi = styled.li`
  padding: 16px 12px 16px 0;
  border-bottom: 1px solid #d8d8d8;
  & > section {
    display: flex;
    & h3 {
      font-family: ${({ theme }) => theme.fontFamily2};
      word-break: break-word;
      font-size: 18px;
      line-height: 24px;
      font-weight: normal;
      margin: 0 0 8px 0;
    }
  }
`;

const BookImageWrapper = styled.div`
  min-width: 135px;
  max-width: 135px;
  min-height: 135px;
  max-height: 150px;
  text-align: center;
  align-items: center;
  & > img {
    max-width: 135px;
    max-height: 150px;
  }
`;

const BookDetailCol = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-bottom: 4px;
`;

const BookDetailLine = styled.div`
  color: #4d4d4d;
  font-size: 16px;
  display: flex;
  margin-top: 8px;
  font-weight: normal;
  word-break: break-word;
  font-family: ${({ theme }) => theme.fontFamily2};
`;

const BookPrice = styled.span`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily1};
  color: rgb(32 106 202);
`;

const BookAvailability = styled.span`
  font-weight: bold;
  text-align: right;
  color: ${({ available, theme }: any) =>
    available ? theme.color.positive : theme.color.negative};
`;
