import { FC, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Locale } from "../../common/types";
import routes, { LOCALE_PREFIX_PATTERN } from "../../routes";

/**
 * Sets alt lang tag in head
 * e.g. <link rel="alternate" hreflang="lang_code" href="url_of_page" />
 * https://developers.google.com/search/docs/advanced/crawling/localized-versions#html
 */
const SeoLangRelTag: FC = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const linkTags = useRef<any[]>([]);

  useEffect(() => {
    const routeMatch = routes.match(pathname)[0];

    if (!routeMatch) {
      return;
    }

    const { langCode, urlLang } = alternateLang[i18n.language as Locale];

    linkTags.current.push(
      createLinkTag(
        langCode,
        window.location.origin +
          routes.to(
            routeMatch.name,
            {
              ...routeMatch.params,
              locale: urlLang,
            },
            LOCALE_PREFIX_PATTERN
          )
      )
    );

    linkTags.current.forEach((node: any) => document.head.appendChild(node));

    return () => {
      linkTags.current.forEach((node: any) => document.head.removeChild(node));
      linkTags.current = [];
    };
  }, [pathname, i18n.language]);

  return <></>;
};

export default SeoLangRelTag;

const createLinkTag = (langCode: string, url: string) => {
  const result = document.createElement("link");
  result.setAttribute("rel", "alternate");
  result.setAttribute("hreflang", langCode);
  result.setAttribute("href", url);
  return result;
};

const alternateLang: {
  [locale in Locale]: { langCode: string; urlLang: string };
} = {
  EN_US: { langCode: "el", urlLang: "gr" },
  EL_GR: { langCode: "en", urlLang: "en" },
};
