import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

import { MenuIcon } from "../../../common/components/icons";
import {
  MAX_X_SMALL,
  useResponsive,
} from "../../../common/providers/responsive";
import { sendEvent } from "../../../common/util/analytics";
import { OnClickHandler } from "../../../common/types";
import LocaleSwitcher from "../elements/LocaleSwitcher";
import SearchBox from "./SearchBox";

const TopBar: FC<{ interpolation: number; onClickMenuBtn: OnClickHandler }> = ({
  interpolation,
  onClickMenuBtn,
}) => {
  const { t } = useTranslation("header");
  const { xSmall, small } = useResponsive();
  return (
    <TopBarWrapper>
      <Container>
        <TopBarContainer>
          {xSmall ? <MenuButton onClick={onClickMenuBtn} t={t} /> : null}
          <HomeLink href="/" onClick={() => sendEvent("clickHome")}>
            <PageTitle>
              <span
                style={{
                  fontSize: "43.05px",
                  display: "inline-block",
                  transform: `translate3d(0, ${79 - 79 * interpolation}px, 0)`,
                }}
              >
                Retsas
              </span>{" "}
              <span
                style={{
                  display: "inline-block",
                  transform: `translate3d(4px, ${
                    112 - 112 * interpolation
                  }px, 0)`,
                }}
              >
                books
              </span>
            </PageTitle>
          </HomeLink>
          <div style={{ flexGrow: 1 }} />
          {xSmall ? null : <SearchBox />}
          {small || xSmall ? null : <div style={{ marginLeft: 16 }} />}
          <LocaleSwitcher />
        </TopBarContainer>
      </Container>
    </TopBarWrapper>
  );
};

const MenuButton = ({
  onClick,
  t,
}: {
  onClick: OnClickHandler;
  t: (key: string) => string;
}) => (
  <MenuButtonContainer onClick={onClick} aria-label={t("menu_btn_label")}>
    <MenuIcon />
  </MenuButtonContainer>
);

const MenuButtonContainer = styled.button`
  margin: 0 8px 0 -8px;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 400px;
  &:active {
    background: #0000001f;
  }
`;

const HomeLink = styled.a`
  color: #111;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const PageTitle = styled.h1`
  font-weight: 400;
  font-size: 2.05em;
  font-family: ${({ theme }) => theme.fontFamily1};
  padding-left: 39px;
  min-width: 255px;
  margin-block-start: 0;
  margin-block-end: 0;

  @media ${MAX_X_SMALL} {
    padding-left: 0;
  }
`;

const TopBarWrapper = styled.div`
  position: fixed;
  width: 100%;
  background-color: rgba(250, 250, 250, 0.5);
  @media ${MAX_X_SMALL} {
    background-color: rgba(250, 250, 250, 0.25);
  }
`;

const TopBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  align-items: center;
`;

export default TopBar;
