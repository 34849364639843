import { useEffect, useRef, useState } from "react";
import { ApolloClient, useApolloClient, useLazyQuery } from "@apollo/client";

import getBooks, { BookVariant } from "../get-books";
import PaginatedResult from "../util/PaginatedResult";
import { Author, Book, newBook, SortOrder } from "../types";
import {
  FIND_BOOKS,
  GET_BOOK,
  GET_BOOKS_BY_AUTHOR,
  GET_BOOKS_WITH_KEYWORD,
  GET_BOOKS_IN_CATEGORY,
  GET_FEATURED_BOOKS,
} from "../gql";

export type BooksFilters = {
  author?: Author;
  filterCategory?: string;
  order?: SortOrder;
  searchQuery?: string;
  featured?: boolean;
  keyword?: string;
};

type UseBooksValue = {
  error?: Error;
  books: PaginatedResult<Book>;
  pending: boolean;
};

const defaultUseBooksValue = {
  error: undefined,
  books: PaginatedResult.pendingPlaceholder,
  pending: false,
};

export const useBooks = (
  query: BooksFilters,
  initialPageNumber: number = 0,
  initialPageSize: number = 10,
  variant: BookVariant = "admin_list"
) => {
  const queryNumber = useRef(0);
  const [value, setValue] = useState<UseBooksValue>(defaultUseBooksValue);
  const client = useApolloClient();

  useEffect(() => {
    // This is used to avoid race conditions
    queryNumber.current += 1;
    const currentQueryNumber = queryNumber.current;

    setValue((value) => ({
      ...value,
      books: PaginatedResult.pendingPlaceholder,
      pending: true,
    }));
    queryBooks(client, query)
      .then(({ data, error }) => {
        if (data && currentQueryNumber === queryNumber.current) {
          setValue({
            books: new PaginatedResult<Book>(
              data.bookIds,
              async (ids) => {
                const { data, error } = await getBooks(client, ids);
                if (error) {
                  setValue((value) => ({ ...value, error, pending: false }));
                }
                // Note we trust that the books are returned in the order they were requested
                return data?.books;
              },
              initialPageNumber,
              initialPageSize,
              data.bookIds.length
            ),
            error: undefined,
            pending: false,
          });
        }
      })
      .catch((error) => {
        console.error("Failed to fetch books list:", { query }, error);
        setValue((value) => ({ ...value, error }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.author,
    query.filterCategory,
    query.order,
    query.searchQuery,
    query.featured,
  ]);

  useEffect(
    () =>
      value.books.onUpdate((pageState) =>
        setValue((value) => ({ ...value, pending: value.books.isPending() }))
      ),
    [value.books]
  );

  return value;
};

const queryBooks = (
  client: ApolloClient<any>,
  {
    author,
    filterCategory,
    order = "DESC",
    searchQuery,
    featured,
    keyword,
  }: BooksFilters
) => {
  if (author) {
    return client.query({
      query: GET_BOOKS_BY_AUTHOR,
      variables: {
        nativeName: author.nativeName,
        greekName: author.greekName,
      },
    });
  }
  if (filterCategory && !searchQuery) {
    return client.query({
      query: GET_BOOKS_IN_CATEGORY,
      variables: {
        categoryId: filterCategory,
        reverse: order === "DESC",
      },
    });
  }
  if (featured) {
    return client.query({ query: GET_FEATURED_BOOKS });
  }
  if (keyword) {
    return client.query({
      query: GET_BOOKS_WITH_KEYWORD,
      variables: { keyword },
    });
  }
  return client.query({
    query: FIND_BOOKS,
    variables: { searchQuery },
  });
};

type UseBookValue = {
  book: Book | undefined;
  ready: boolean;
  error?: Error;
  forceUpdate: () => void;
};

export const useBook = (bookId?: string): UseBookValue => {
  const [value, setValue] = useState<UseBookValue>(() => ({
    book: newBook(),
    ready: true,
    error: undefined,
    forceUpdate: () => {},
  }));

  // TODO: use lazy query because bookId could be null! and for better force!

  const [refetch, { called, loading, error, data }] = useLazyQuery(GET_BOOK, {
    variables: { bookId },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!called && bookId) refetch()
  }, [bookId, called, refetch])

  useEffect(() => {
    if (bookId) {
      setValue({
        book: data?.books[0],
        ready: data && !loading,
        error,
        forceUpdate: refetch,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, loading, error, data, refetch]);

  return value;
};
