import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { usePageIndex } from "../../../common/hooks/use-page-index";
import { Book } from "../../../common/types";
import PaginatedResult from "../../../common/util/PaginatedResult";
import { RightChevron, LeftChevron } from "../../../common/components/icons";

type BookListingPaginationProps = {
  books: PaginatedResult<Book>;
  radius?: number;
};

const BookListingPagination: FC<BookListingPaginationProps> = ({
  books,
  radius = 4, // TODO: make responsive, i.e. 2 or 3 on smaller screens
}) => {
  const { pageIndex, getUpdatedLink } = usePageIndex();
  const { activePageIndex } = books;
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    books.setPage(pageIndex);
    if (!firstLoad) {
      window.scrollTo(0, 110);
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  if (books.numPages < 2) {
    return null;
  }

  const maxIndex = books.numPages - 1;
  const rangeMin =
    activePageIndex > books.numPages - radius - 1
      ? Math.max(books.numPages - radius * 2 - 1, 0)
      : activePageIndex - radius - 2 > 0
      ? activePageIndex - radius
      : 0;
  const rangeMax =
    activePageIndex < radius + 1
      ? Math.min(radius * 2 + 1, maxIndex)
      : activePageIndex + radius + 2 < maxIndex
      ? activePageIndex + radius
      : maxIndex;
  const showRangeStartAsExtra = rangeMin > 0;
  const showRangeEndAsExtra = rangeMax < maxIndex;

  return (
    <PaginationContainer>
      <EndPaginationItem disabled={activePageIndex === 0}>
        <Link to={getUpdatedLink(Math.max(0, activePageIndex - 1))} rel="prev">
          <LeftChevron />
        </Link>
      </EndPaginationItem>
      {showRangeStartAsExtra && (
        <>
          <PaginationItemRange books={books} firstIndex={0} />
          {rangeMin > 1 ? <Ellipses /> : null}
        </>
      )}
      <PaginationItemRange
        books={books}
        firstIndex={rangeMin}
        lastIndex={rangeMax}
      />
      {showRangeEndAsExtra && (
        <>
          {rangeMax < maxIndex ? <Ellipses /> : null}
          <PaginationItemRange books={books} firstIndex={maxIndex} />
        </>
      )}
      <EndPaginationItem disabled={activePageIndex >= maxIndex}>
        <Link
          to={getUpdatedLink(
            Math.min(books.numPages, Math.min(activePageIndex + 1, maxIndex))
          )}
          rel="next"
        >
          <RightChevron />
        </Link>
      </EndPaginationItem>
    </PaginationContainer>
  );
};

type PaginationItemRangeProps = {
  books: PaginatedResult<Book>;
  firstIndex?: number;
  lastIndex?: number;
};

const PaginationItemRange: FC<PaginationItemRangeProps> = ({
  books,
  firstIndex = 0,
  lastIndex,
}) => {
  const { getUpdatedLink } = usePageIndex();
  return (
    <>
      {inclusiveRange(firstIndex ?? lastIndex, lastIndex ?? firstIndex).map(
        (index) => (
          <PaginationItem
            key={index}
            selected={index === books.activePageIndex}
          >
            <Link to={getUpdatedLink(index)}>{index + 1}</Link>
          </PaginationItem>
        )
      )}
    </>
  );
};

function inclusiveRange(arg1: number, arg2?: number) {
  const start = arg2 === undefined ? 0 : arg1;
  const end = arg2 === undefined ? arg1 : arg2;

  const result = [];
  for (var i = start; i <= end; ++i) {
    result.push(i);
  }
  return result;
}

export default BookListingPagination;

const PaginationContainer = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily1};
`;

type PaginationItemProps = {
  selected?: boolean;
};

const PaginationItem = styled.div`
  margin: 0 6px;

  font-weight: ${(props: PaginationItemProps) =>
    props.selected ? "bold" : "inherit"};

  & > a {
    text-decoration: none;
    user-select: none;
    color: inherit;
    width: 36px;
    height: 36px;
    border-radius: 25px;
    border: solid 1px #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    ${(props: PaginationItemProps) =>
      props.selected
        ? `background: linear-gradient(180deg, rgb(88 140 213), rgba(20, 99, 200, 1) 80%);
           background-color: #1463c9;
           color: #eee;
           box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);
           border: none;`
        : `background: none`}
  }
`;

const Ellipses = styled.div.attrs({ children: "• • •" })`
  text-decoration: none;
  user-select: none;
  color: #ccc;
  padding: 9px 8px 0 8px;
  height: 30px;
  overflow: hidden;
`;

const EndPaginationItem = styled(PaginationItem)`
  & > a {
    border: none;
    fill: ${(props: { disabled?: boolean }) =>
      props.disabled ? "#ccc" : "inherit"};
    cursor: ${(props: { disabled?: boolean }) =>
      props.disabled ? "default" : "pointer"};
  }
`;
