import { FC, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAuthorsKeywords } from "../../common/providers/authorskeywords";
import { usePageMeta } from "../../common/providers/metadata";
import { useResponsive } from "../../common/providers/responsive";
import { Author, Dictionary, formatAuthor } from "../../common/types";
import routes from "../../routes";
import FullPageErrorMessage from "../components/elements/FullPageErrorMessage";
import { MainHeader, PlaceholderRect } from "../components/styled";

const WORD_CHAR_PATTERN = /[\wΑ-Ωα-ω]/;
const NUM_CHAR_PATTERN = /[0-9]/;
const GREEK_CHAR_PATTERN = /[Α-Ωα-ω]/;

type AuthorsIndex = {
  greek: { [char: string]: Set<Author> };
  other: { [char: string]: Set<Author> };
  special: { [char: string]: Set<Author> };
};

const AuthorsIndexPage: FC = () => {
  const {
    authors: { authors, loading, error },
  } = useAuthorsKeywords({ authors: true });

  const { i18n, t } = useTranslation("elements");
  const { setPageMeta } = usePageMeta();
  const location = useLocation();
  const activeIndexKey = decodeURIComponent(location.hash).slice(1, 2);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setPageMeta({ title: t("authors_header") }), [i18n.language]);

  const authorsIndex: AuthorsIndex = useMemo(
    () =>
      authors.reduce(
        (result: AuthorsIndex, author: Author) => {
          [author.nativeName, author.greekName].forEach((name) => {
            const firstCharMatch = name?.match(WORD_CHAR_PATTERN);
            if (firstCharMatch) {
              const indexChar = firstCharMatch[0].toUpperCase();
              let pushTarget: Set<Author>;
              if (indexChar.match(GREEK_CHAR_PATTERN)) {
                pushTarget =
                  result.greek[indexChar] ??
                  (result.greek[indexChar] = new Set());
              } else if (name!.match(NUM_CHAR_PATTERN)) {
                pushTarget =
                  result.special["#"] ?? (result.special["#"] = new Set());
              } else {
                // Take the last initial of a non-greek name
                const nameParts = name!
                  .trim()
                  // eslint-disable-next-line no-useless-escape
                  .split(/[\s\.]/)
                  .filter((x) => x.length);
                const lastInitial = (
                  nameParts[nameParts.length - 1] ?? ""
                ).trim()[0];
                const targetIndex = lastInitial.match(GREEK_CHAR_PATTERN)
                  ? result.greek
                  : result.other;
                pushTarget =
                  targetIndex[lastInitial] ??
                  (targetIndex[lastInitial] = new Set());
              }
              pushTarget.add(author);
            }
          });
          return result;
        },
        { greek: {}, other: {}, special: {} }
      ),
    [authors]
  );

  const authorsList: Author[] = useMemo(
    () =>
      Array.from(
        authorsIndex.other[activeIndexKey] ??
          authorsIndex.greek[activeIndexKey] ??
          authorsIndex.special[activeIndexKey] ??
          []
      ).sort(compareAuthors),
    [authorsIndex, activeIndexKey]
  );

  if (loading && !error && !authors.length) {
    return <AuthorsIndexNavPlaceholder header={t("authors_header")} />;
  }
  if (error) {
    return <FullPageErrorMessage messageKey="unknown_error" />;
  }

  return (
    <div>
      <MainHeader>{t("authors_header")}</MainHeader>

      <IndexNav
        indexes={[authorsIndex.greek, authorsIndex.special]}
        active={activeIndexKey}
        greek
      />
      <IndexNav indexes={[authorsIndex.other]} active={activeIndexKey} />

      {authorsList.length ? (
        <AuthorsList>
          {authorsList.map((author) => {
            const formatted = formatAuthor(author);
            return (
              <AuthorItem key={formatted}>
                <Link
                  to={routes.to("author-books", {
                    nativeName: author.nativeName,
                    ...(author.greekName
                      ? { greekName: author.greekName }
                      : {}),
                  })}
                >
                  {formatted}
                </Link>
              </AuthorItem>
            );
          })}
        </AuthorsList>
      ) : (
        <AuthorsIndexPageIntro>{t("authors_intro")}</AuthorsIndexPageIntro>
      )}
    </div>
  );
};

function compareAuthors(a: Author, b: Author) {
  let nameA = ((a.greekName || a.nativeName) ?? "").match(/[("[\-\w]*(.*)/)?.[1] ?? ""
  let nameB = ((b.greekName || b.nativeName) ?? "").match(/[("[\-\w]*(.*)/)?.[1] ?? ""
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
}

export default AuthorsIndexPage;

const AuthorsIndexPageIntro = styled.p``;

const AuthorsIndexNavPlaceholder = ({ header }: { header: string }) => {
  const { xSmall } = useResponsive();

  if (xSmall) {
    return (
      <div>
        <MainHeader>{header}</MainHeader>
        <PlaceholderRect width={"95%"} height={20} mt={24} ml={8} />
        <PlaceholderRect width={"85%"} height={20} mt={24} ml={8} />
        <PlaceholderRect width={"95%"} height={20} mt={24} ml={8} />
        <PlaceholderRect width={"85%"} height={20} mt={24} ml={8} />
      </div>
    );
  }

  return (
    <div>
      <MainHeader>{header}</MainHeader>
      <PlaceholderRect width={610} height={20} mt={24} ml={8} />
      <PlaceholderRect width={690} height={20} mt={15} ml={8} />
    </div>
  );
};

const AuthorsList = styled.ul`
  padding: 0;
  list-style: none;
`;

const AuthorItem = styled.li`
  padding-bottom: 2px;
}
`;

const IndexNav: FC<{
  indexes: Dictionary<unknown>[];
  active: string;
  greek?: boolean;
}> = ({ indexes, active, greek = false }) => (
  <IndexNavList greek={greek}>
    {indexes.reduce(
      (elems: any[], index) =>
        elems.concat(
          Object.keys(index)
            .sort()
            .map((key) => (
              <IndexNavItem
                key={key}
                className={key === active ? "active" : undefined}
              >
                <Link to={`#${key}`}>{key}</Link>
              </IndexNavItem>
            ))
        ),
      []
    )}
  </IndexNavList>
);

const IndexNavList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: row;
  flex-wrap: wrap;
  & > .active {
    color: #eee;
    border-radius: 4px;
    font-weight: 600;
    background: #dfdfdf;
  }
  font-family: ${({ theme, greek }: { theme: any; greek: boolean }) =>
    greek ? theme.fontFamily2 : theme.fontFamily1};
`;

const IndexNavItem = styled.li`
  width: 26px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0px;
  text-align: center;
  box-sizing: border-box;
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #333;
    min-width: 15px;
    height: 30px;
  }
`;
