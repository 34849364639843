import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";

import "./global.css";
import theme from "./theme.json";
import "./modernizr-webp.js";

import { useWebp } from "../common/hooks/use-webp";
import { ResponsiveProvider } from "../common/providers/responsive";
import { parseSearchParams } from "../common/util/querystring";
import routes from "../routes";
import PageLayout from "./components/layout/PageLayout";
import Fonts from "./fonts";
import AuthorPage from "./pages/AuthorPage";
import AuthorsIndexPage from "./pages/AuthorsIndexPage";
import BookPage from "./pages/BookPage";
import CategoryPage from "./pages/CategoryPage";
import ContactPage from "./pages/ContactPage";
import Error404 from "./pages/Error404";
import HomePage from "./pages/HomePage";
import KeywordsPage from "./pages/KeywordsPage";
import SearchResultsPage from "./pages/SearchResultsPage";

const Website = () => {
  const { i18n } = useTranslation("book_page");

  const webpSupport = useWebp();

  useLocalizationPrefix(i18n);

  // @ts-ignore
  theme.fontFamily =
    i18n.language === "EL_GR" ? theme.fontFamily2 : theme.fontFamily1;
  // @ts-ignore
  theme.webp = webpSupport.alpha;

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveProvider>
        {/* @ts-ignore */}
        <Fonts />
        <PageLayout>
          <Switch>
            <Route
              path={routes.get("author-books")}
              render={({ match: { params } }) => (
                <AuthorPage
                  nativeName={params.nativeName as string}
                  greekName={params.greekName ?? ""}
                />
              )}
            />
            <Route
              path={routes.get("authors-index")}
              render={() => <AuthorsIndexPage />}
            />
            <Route
              path={routes.get("book-details")}
              render={({ match: { params } }) => (
                <BookPage bookId={params.bookId!} />
              )}
            />
            <Route
              path={routes.get("category-books")}
              render={({ match }) => (
                <CategoryPage categoryId={match.params.categoryId as string} />
              )}
            />
            <Route
              path={routes.get("contact")}
              render={() => <ContactPage />}
            />
            <Route
              path={routes.get("keywords-index")}
              render={() => <KeywordsPage />}
            />
            <Route
              path={routes.get("search-results")}
              render={({ location }) => (
                <SearchResultsPage search={location.search as string} />
              )}
            />
            <Route
              path={routes.get("root")}
              exact
              render={() => <HomePage />}
            />

            <Route path="/index.php" exact component={RedirectHelper} />
            <Route path="/index.html" exact component={RedirectHelper} />

            <Route render={() => <Error404 />} />
          </Switch>
        </PageLayout>
      </ResponsiveProvider>
    </ThemeProvider>
  );
};

export default Website;

const RedirectHelper = ({
  location,
  history,
}: {
  location: Location;
  history: any;
}) => {
  // Redirect from legacy routes
  const params = parseSearchParams(location.search);
  if (params.instance === "book" && params.id) {
    import("./redirects.json").then(
      ({ default: redirects }: { default: any }) => {
        let bookId = params.id;
        if (redirects.hasOwnProperty(bookId)) {
          bookId = `${redirects[bookId] as number}`;
        }
        history.replace(routes.to("book-details", { bookId }));
      }
    );
  } else if (params.instance === "books" && params.id) {
    history.replace(routes.to("category-books", { categoryId: params.id }));
  } else if (params.instance === "authors") {
    history.replace(routes.to("authors-index"));
  } else if (params.instance === "contact" || params.instance === "ordering") {
    history.replace(routes.to("contact"));
  } else {
    history.replace(routes.to("root"));
  }
  return <></>;
};

const useLocalizationPrefix = (i18n: any) => {
  // Update prefix for all routes to match the current locale directory
  if (i18n.language === "EL_GR") {
    routes.setLocale("gr");
  } else if (i18n.language === "EN_US") {
    routes.setLocale("en");
  }
};
