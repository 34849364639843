import { useEffect, useState } from "react";

const fastWebPCheck =
  document
    .createElement("canvas")
    .toDataURL("image/webp")
    .indexOf("data:image/webp") === 0;

export const useWebp = () => {
  const [webpSupport, setWebpSupport] = useState<any>({ alpha: fastWebPCheck });

  useEffect(() => {
    // @ts-ignore
    Modernizr.on("webp", setWebpSupport);
  }, []);

  return webpSupport;
};
