import { gql } from "@apollo/client";

export const GET_CATEGORIES_WITH_LOCALE = gql`
  query getCategories($locale: Locale) {
    categories: getCategories(locale: $locale) {
      id
      locale
      superCategory
      subCategory
      displayOrder
      size
    }
  }
`;

export const GET_BOOKS_IN_CATEGORY = gql`
  query getBooksInCategory($categoryId: String!, $reverse: Boolean!) {
    bookIds: getBooksInCategory(categoryId: $categoryId, reverse: $reverse)
  }
`;

export const GET_BOOKS_BY_AUTHOR = gql`
  query getBooksByAuthor($nativeName: String!, $greekName: String!) {
    bookIds: getBooksByAuthor(nativeName: $nativeName, greekName: $greekName)
  }
`;

export const GET_BOOKS_WITH_KEYWORD = gql`
  query getBooksWithKeyword($keyword: String!) {
    bookIds: getBooksWithKeyword(keyword: $keyword)
  }
`;

export const GET_BOOK = gql`
  query getBook($bookId: ID!) {
    books: getBooks(ids: [$bookId]) {
      id
      title
      price
      categoryIds
      authors {
        greekName
        nativeName
      }
      available
      bookNumber
      createdAt
      dedication
      description
      edition
      featured
      hidden
      illustration
      images {
        bookId
        number
        extension
      }
      year
      volumes
      updatedAt
      translator
      publisher
      place
      pages
      lockVersion
      location
      keywords
    }
  }
`;

export const FIND_BOOKS = gql`
  query findBooks($searchQuery: String!) {
    bookIds: findBooks(query: $searchQuery)
  }
`;

export const GET_FEATURED_BOOKS = gql`
  query getFeaturedBooks {
    bookIds: getFeaturedBooks
  }
`;

export const AUTHORS_QUERY = gql`
  query getAuthors($version: Int!) {
    authors: getAuthors(version: $version) {
      nativeName
      greekName
    }
    version: getCacheVersion
  }
`;

export const KEYWORDS_QUERY = gql`
  query getKeywords($version: Int!) {
    keywords: getKeywords(version: $version)
    version: getCacheVersion
  }
`;
