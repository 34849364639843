import * as React from "react";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";

import getBooks from "../get-books";
import { GET_FEATURED_BOOKS } from "../gql";
import { Book } from "../types";
import PaginatedResult from "../util/PaginatedResult";

type FeaturedBooksContextValue = {
  loading: boolean;
  error: Error | undefined;
  featuredBooks: PaginatedResult<Book>;
  initFeaturedBooks: (pageSize: number) => void;
};

const defaultFeaturedBooksContext = {
  loading: false,
  error: undefined,
  featuredBooks: PaginatedResult.pendingPlaceholder,
  initFeaturedBooks: () => undefined,
};

const FeaturedBooksContext = React.createContext<FeaturedBooksContextValue>(
  defaultFeaturedBooksContext
);

export const FeaturedBooksProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const client = useApolloClient();
  const [pageSize, setPageSize] = useState(0);
  const { loading, error, data } = useQuery(GET_FEATURED_BOOKS, {
    skip: pageSize < 1,
  });
  const [status, setStatus] = useState({ loading, error });

  useEffect(() => {
    setStatus({ loading, error });
  }, [loading, error]);

  const featuredBooks: PaginatedResult<Book> = useMemo(() => {
    const bookIds = [...(data?.bookIds ?? [])];
    bookIds.sort(() => (Math.random() > 0.5 ? 1 : -1));
    return data?.bookIds
      ? new PaginatedResult<Book>(
          bookIds,
          async (ids) => {
            const { data, error } = await getBooks(client, ids);
            if (error) {
              setStatus(() => ({ error, loading: false }));
            }
            // N.B. we trust that the books are returned in the order they were requested
            return data?.books;
          },
          0,
          pageSize ?? 10,
          data?.bookIds.length
        )
      : PaginatedResult.pendingPlaceholder;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- no client or pageSize
  }, [loading, error, data]);

  const value = useMemo(
    () => ({
      ...status,
      featuredBooks,
      initFeaturedBooks: (pageSize: number) => {
        setPageSize(pageSize);
        if (data) {
          featuredBooks.setPage(0, pageSize);
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- no data
    [status, featuredBooks]
  );

  return (
    <FeaturedBooksContext.Provider value={value}>
      {children}
    </FeaturedBooksContext.Provider>
  );
};

export const useFeaturedBooks = () => useContext(FeaturedBooksContext);
