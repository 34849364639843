import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { RightChevron, LeftChevron } from "../../common/components/icons";
import { MAX_X_SMALL } from "../../common/providers/responsive";
import { Image } from "../../common/types";
import BookImage from "./BookImage";

type BookImageViewerProps = {
  images: Image[];
  width?: number;
  disableZoom?: boolean;
};

const BookImageViewer: FC<BookImageViewerProps> = ({
  images,
  width = 275,
  disableZoom,
}) => {
  const [selected, setSelected] = useState<number>(0);
  const [zoomImage, setZoomImage] = useState<number>(-1);

  disableZoom = disableZoom || !images.length;

  const toggleZoom = () =>
    disableZoom
      ? setZoomImage(-1)
      : zoomImage >= 0
      ? setZoomImage(-1)
      : setZoomImage(selected);

  return (
    <>
      <Layout>
        <ImageArea
          tabIndex={disableZoom ? -1 : 0}
          disableZoom={disableZoom}
          onClick={toggleZoom}
          onKeyPress={({ which }) => which === 13 && toggleZoom()}
          width={width}
        >
          <BookImage
            alt="book image"
            image={images[selected]}
            placeholder={{ width: 275, height: 360, mr: 16, fontSize: 40 }}
          />
        </ImageArea>
        {images.length < 2 ? null : (
          <ThumbnailArea>
            {images.map((image, index) => (
              <ThumbnailWrapper
                key={index}
                onClick={() => setSelected(index)}
                active={index === selected}
              >
                <BookImage alt="book image thumbnail" image={image} />
              </ThumbnailWrapper>
            ))}
          </ThumbnailArea>
        )}
      </Layout>
      <BookImageZoomModal
        images={images}
        focusIndex={disableZoom ? -1 : zoomImage}
        onChangeFocus={setZoomImage}
      />
    </>
  );
};

const Layout = styled.div`
  margin-bottom: 8px;
  user-select: none;
`;

type ImageAreaProps = { disableZoom: boolean; width: number };
const ImageArea = styled.div`
  cursor: ${({ disableZoom }: ImageAreaProps) =>
    disableZoom ? "inherit" : "zoom-in"};
  min-height: 200px;
  max-height: 375px;
  text-align: center;
  & > img {
    max-width: ${({ width }: ImageAreaProps) => width}px;
    max-height: 375px;
  }
`;

const ThumbnailArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 4px;
`;

const ThumbnailWrapper = styled.div`
  flex-grow: 0;
  margin-top: 8px;
  margin-bottom: 6px;
  margin-right: 12px;
  box-shadow: 0px 1px 4px #000000a6;
  cursor: pointer;
  height: 45px;
  & > img {
    height: 45px;
  }
  ${({ active, theme }: { active: boolean; theme: any }) =>
    active
      ? `
    height: 55px;
    border-radius: 4px;
    border: 2px solid ${theme.color.primary};
    padding: 3px;
    margin-top: 3px;
    margin-left: -5px;
    margin-right: 7px;
    margin-bottom: 7px;
`
      : ""}
`;

const BookImageZoomModal: FC<{
  images: Image[];
  focusIndex: number;
  onChangeFocus: (newIndex: number) => void;
}> = ({ images, focusIndex, onChangeFocus }) => {
  const disabledPrev = focusIndex <= 0;
  const disabledNext = focusIndex >= images.length - 1;
  const [focusedImage, setFocusedImage] = useState(images[focusIndex]);

  useEffect(() => {
    if (focusIndex >= 0) setFocusedImage(images[focusIndex]);
  }, [images, focusIndex]);

  return (
    <Modal
      open={focusIndex >= 0}
      onClose={() => onChangeFocus(-1)}
      center
      styles={{
        modal: { padding: 0, borderRadius: 4, marginLeft: 0, marginRight: 0 },
        closeButton: { top: 8, right: 6 },
      }}
    >
      <ModalContainer>
        <ActionWrapper
          tabIndex={disabledPrev ? -1 : 0}
          disabled={disabledPrev}
          onKeyPress={({ which }) =>
            which === 13 && onChangeFocus(Math.max(0, focusIndex - 1))
          }
          onClick={() => onChangeFocus(Math.max(0, focusIndex - 1))}
        >
          <LeftChevron />
        </ActionWrapper>
        <BookImage alt="book image" image={focusedImage} />
        <ActionWrapper
          tabIndex={disabledNext ? -1 : 0}
          disabled={disabledNext}
          onKeyPress={({ which }) =>
            which === 13 &&
            onChangeFocus(Math.min(images.length - 1, focusIndex + 1))
          }
          onClick={() =>
            onChangeFocus(Math.min(images.length - 1, focusIndex + 1))
          }
        >
          <RightChevron />
        </ActionWrapper>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  max-width: 98vw;
  max-height: 98vh;
`;
const ActionWrapper = styled.div`
  width: 50px;
  cursor: ${(props: { disabled?: boolean }) =>
    props.disabled ? "inherit" : "pointer"};
  opacity: ${(props: { disabled?: boolean }) => (props.disabled ? 0.4 : 1)};
  @media ${MAX_X_SMALL} {
    width: 25px;
    min-width: 25px;
  }
`;

/*
 * Opening the modal causes a crash on IE
 * Solution is to forget about opening the modal if this happens.
 */
class BookImageViewerErrorBoundary extends React.Component<
  BookImageViewerProps
> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.log("Error in Zoom image modal", error, errorInfo);
  }

  render() {
    return (
      <BookImageViewer {...this.props} disableZoom={this.state.hasError} />
    );
  }
}

export default BookImageViewerErrorBoundary;
