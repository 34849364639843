import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BookListing from "../components/elements/BookListing";
import { MainHeader } from "../components/styled";
import { parseSearchParams } from "../../common/util/querystring";

type SearchResultsPageProps = {
  search: string;
};

const SearchResultsPage: FC<SearchResultsPageProps> = ({ search }) => {
  const { t } = useTranslation("elements");

  const searchQuery = parseSearchParams(search).q ?? "";
  const booksFilter = useMemo(() => ({ searchQuery }), [searchQuery]);

  const returnAction: [string, string] = useMemo(
    () => ["return_search", "{}"],
    []
  );

  return (
    <>
      <MainHeader>
        {t("h_search_results")}: {searchQuery}
      </MainHeader>
      <BookListing
        filter={booksFilter}
        returnAction={returnAction}
        errorKey="no_results"
      />
    </>
  );
};

export default SearchResultsPage;
