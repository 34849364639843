import { ApolloClient, gql } from "@apollo/client";

export type BookVariant = "admin_list" | "full";

const getBooksQuery = (variant: BookVariant) => {
  let fields = ["id", "title"];
  switch (variant) {
    // @ts-ignore
    case "full":
      fields.push(
        "authors { greekName nativeName }",
        "images { bookId number extension }",
        "bookNumber",
        "createdAt",
        "dedication",
        "description",
        "edition",
        "featured",
        "hidden",
        "illustration",
        "keywords",
        "lockVersion",
        "location",
        "publisher",
        "pages",
        "translator",
        "updatedAt",
        "volumes"
      );
    // eslint-disable-next-line no-fallthrough
    default:
      fields.push("authorship", "available", "place", "price", "year");
  }
  return gql`
    query getBooks($bookIds: [ID!]!) {
      books: getBooks(ids: $bookIds) { ${fields.join(" ")} }
    }
  `;
};

const getBooks = (
  client: ApolloClient<any>,
  bookIds: string[],
  variant: BookVariant = "full"
) => {
  return client.query({
    query: getBooksQuery(variant),
    variables: { bookIds },
  });
};

export default getBooks;
