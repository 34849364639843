import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { Locale } from "./types";
import websiteTranslations from "../website/translations.json";

export const LOCALES: Locale[] = ["EL_GR", "EN_US"];

export type LanguageCode = "gr" | "en";

export const SHORT_LOCALE: { [long in Locale]: LanguageCode } = {
  EN_US: "en",
  EL_GR: "gr",
};

export const LONG_LOCALE: { [short in LanguageCode]: Locale } = {
  en: "EN_US",
  gr: "EL_GR",
};

function loadTranslations(translations: any) {
  for (const locale in translations) {
    for (const namespace in translations[locale]) {
      i18n.addResources(locale, namespace, translations[locale][namespace]);
    }
  }
}

function detectLanguage(): Locale {
  // Always use locale from url if provided
  const { pathname } = window.location;
  const localeChars = pathname.slice(1, 3);
  if (pathname.match(/^\/\w\w(\/.*)?$/) && localeChars in LONG_LOCALE) {
    return LONG_LOCALE[localeChars as LanguageCode];
  }

  // Prefer locale saved to localeStorage
  const i18nextLng = window?.localStorage?.i18nextLng;
  if (LOCALES.includes(i18nextLng)) return i18nextLng;

  // Try match against the primary browser language
  const browserLanguage = window?.navigator?.language ?? "el-GR";
  if (browserLanguage.startsWith("el")) return "EL_GR";
  if (browserLanguage.startsWith("en")) return "EN_US";

  // Try match against the secondary browser languages in order
  const browserLanguages = window?.navigator?.languages ?? [];
  for (const locale of browserLanguages) {
    if (locale.startsWith("el")) return "EL_GR";
    if (locale.startsWith("en")) return "EN_US";
  }

  // Default to greek
  return "EL_GR";
}

i18n.use(initReactI18next).init({
  resources: {},
  ns: ["website", "locale", "admin"],
  defaultNS: "website",
  debug: false,
  lng: detectLanguage(),
  fallbackLng: "EL_GR",
  interpolation: {
    escapeValue: false,
  },
});

i18n.on(
  "languageChanged",
  (language: string) => (window.localStorage.i18nextLng = language)
);

loadTranslations(websiteTranslations);
