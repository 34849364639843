import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { usePageMeta } from "../../common/providers/metadata";
import { formatAuthor } from "../../common/types";
import BookListing from "../components/elements/BookListing";
import { MainHeader } from "../components/styled";

type AuthorPageProps = {
  nativeName: string;
  greekName: string;
};

const AuthorPage: FC<AuthorPageProps> = ({ nativeName, greekName }) => {
  const { i18n, t } = useTranslation("elements");
  const { setPageMeta } = usePageMeta();

  nativeName = decodeURIComponent(nativeName);
  greekName = decodeURIComponent(greekName);

  const booksFilter = useMemo(() => ({ author: { nativeName, greekName } }), [
    nativeName,
    greekName,
  ]);

  const authorName = formatAuthor({ nativeName, greekName });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setPageMeta({ title: authorName }), [i18n.language]);

  const returnAction: [string, string] = useMemo(
    () => ["return_author", `{}: ${authorName}`],
    [authorName]
  );

  return (
    <>
      <MainHeader>
        {t("author_header")}: {authorName}
      </MainHeader>
      <BookListing
        filter={booksFilter}
        returnAction={returnAction}
        errorKey="no_author"
      />
    </>
  );
};

export default AuthorPage;
