import * as pathToRegexp from "path-to-regexp";
import { matchPath } from "react-router";

type RouteDef = { path: string; pathPattern: string; prefixedPath: string };

export type RouteMatch<T> = {
  name: T;
  url: string;
  params: { [key: string]: string };
};

export const LOCALE_PREFIX_PATTERN = "/:locale(en|gr)?";

export class Routes<T extends string> {
  private readonly routes = new Map<T, RouteDef>();

  constructor(private readonly routeSpecs: [T, string][], private locale = "") {
    this.setLocale(locale);
  }

  setLocale(locale: string) {
    if (locale && locale === this.locale) {
      // Skip noop updates since this might be called more often than needed from a component
      return;
    }

    this.locale = locale;

    this.routeSpecs.forEach(([routeName, path]) => {
      this.routes.set(
        routeName,
        Object.freeze({
          path,
          pathPattern: LOCALE_PREFIX_PATTERN + path,
          prefixedPath: locale ? `/${locale}${path}` : path,
        })
      );
    });
  }

  /**
   * Get the raw definition string of a route for using in Route props
   */
  get(routeName: T, overridePrefix?: string): string {
    const route = this.routes.get(routeName);

    if (!route) {
      console.error("Routes: unknown route name", routeName);
      return "";
    }

    if (typeof overridePrefix === "string") {
      return overridePrefix + route.path;
    }

    return route.pathPattern;
  }

  match(pathname: string, exact = true, strict = false): RouteMatch<T>[] {
    return Array.from(this.routes.entries()).reduce(
      (result: RouteMatch<T>[], [routeName, { path, pathPattern }]) => {
        const match = matchPath(pathname, {
          path: pathPattern,
          exact,
          strict,
        });

        if (match) {
          result.push({
            name: routeName,
            url: match.url,
            params: match.params,
          });
        }
        return result;
      },
      []
    );
  }

  /**
   * Get the parameterized route to link to for use in Link props
   */
  to(
    routeName: T,
    paramValues: { [key: string]: string } = {},
    overridePrefix?: string
  ) {
    const route = this.routes.get(routeName);

    if (!route) {
      console.error("Routes: unknown route name", routeName);
      return "";
    }

    if (typeof overridePrefix === "string") {
      return pathToRegexp.compile(overridePrefix + route.path)(paramValues);
    }

    return pathToRegexp.compile(route.prefixedPath)(paramValues);
  }
}

export type RouteNames =
  | "root"
  | "authors-index"
  | "author-books"
  | "book-details"
  | "category-books"
  | "contact"
  | "keywords-index"
  | "keyword-books"
  | "search-results"
  | "admin"
  | "admin-books"
  | "admin-new-book"
  | "admin-edit-book"
  | "admin-sections"
  | "admin-edit-section"
  | "admin-categories"
  | "admin-edit-category";

// All routes will be prefixed with the mount point (i.e. /issue) after setRoutePrefix is called

export default new Routes<RouteNames>([
  ["root", "/"],
  ["authors-index", "/authors"],
  ["author-books", "/authors/:nativeName/:greekName?"],
  ["book-details", "/books/:bookId"],
  ["category-books", "/categories/:categoryId"],
  ["contact", "/contact"],
  ["keywords-index", "/keywords"],
  ["keyword-books", "/keywords/:keyword"],
  ["search-results", "/search"],
  // ---
  ["admin", "/admin"],
  ["admin-books", "/admin/books"],
  ["admin-new-book", "/admin/books/new"],
  ["admin-edit-book", "/admin/books/:bookId"],
  ["admin-sections", "/admin/sections"],
  ["admin-edit-section", "/admin/sections/:sectionId"],
  ["admin-categories", "/admin/categories"],
]);
