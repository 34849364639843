import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { EnglishIcon, GreekIcon } from "../../../common/components/icons";
import { SHORT_LOCALE, LONG_LOCALE, LanguageCode } from "../../../common/i18n";
import {
  MAX_X_SMALL,
  useResponsive,
} from "../../../common/providers/responsive";
import { Locale } from "../../../common/types";
import { sendEvent } from "../../../common/util/analytics";
import routes, { RouteNames, LOCALE_PREFIX_PATTERN } from "../../../routes";

const LocaleSwitcher = () => {
  const { i18n, t } = useTranslation("locale");
  const { xSmall } = useResponsive();
  const locales = Object.keys(i18n.store.data);
  const [menuOpen, setMenuOpen] = useState(false);

  const { pathname, hash, search } = useLocation();
  const routeMatch = useMemo(() => routes.match(pathname)[0], [pathname]) ?? {
    name: "root",
    params: {},
  };

  useEffect(() => {
    const routeLocale = routeMatch?.params.locale as LanguageCode | undefined;
    if (routeLocale) {
      i18n.changeLanguage(LONG_LOCALE[routeLocale]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeMatch]);

  const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const closeMenuOnEsc = useCallback(
    ({ key }: any) => key === "Escape" && setMenuOpen(false),
    [setMenuOpen]
  );

  useEffect(() => {
    if (menuOpen) {
      window.addEventListener("click", closeMenu);
      window.addEventListener("keydown", closeMenuOnEsc);
    }
    return () => {
      window.removeEventListener("click", closeMenu);
      window.removeEventListener("keydown", closeMenuOnEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  return (
    <MenuContainer
      onClick={() => setMenuOpen(!menuOpen)}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.keyCode === 32) {
          e.preventDefault();
          setMenuOpen(!menuOpen);
        }
        // TODO: implement the rest of the keyboard menu interaction
      }}
    >
      <MenuDropdown open={menuOpen}>
        <LocaleItem>
          <LocaleItemLabel>
            {menuOpen || !xSmall ? t(i18n.language) : null}
          </LocaleItemLabel>

          {icons[i18n.language as Locale]}
        </LocaleItem>
        {locales.map((locale) =>
          locale !== i18n.language ? (
            <LocaleItem
              key={locale}
              tabIndex={menuOpen ? 0 : -1}
              hidden={!menuOpen}
              style={{ marginTop: 8 }}
            >
              <Link
                to={{
                  pathname: routes.to(
                    routeMatch.name as RouteNames,
                    {
                      ...routeMatch.params,
                      locale: SHORT_LOCALE[locale as Locale],
                    },
                    LOCALE_PREFIX_PATTERN
                  ),
                  hash,
                  search,
                }}
                onClick={() => sendEvent("switchLocale", { locale })}
              >
                <LocaleItemLabel>{t(locale)}</LocaleItemLabel>
                {icons[locale as Locale]}
              </Link>
            </LocaleItem>
          ) : null
        )}
      </MenuDropdown>
    </MenuContainer>
  );
};

export default LocaleSwitcher;

const MenuContainer = styled.div`
  z-index: 100;
  border-radius: 4px;
  margin-left: 8px;
  height 48px;
  overflow: visible;
  @media ${MAX_X_SMALL} {
    margin-left: -80px;
  }
  @media (max-width: 340px) {
    margin-left: -115px;
  }
`;

const MenuDropdown = styled.div`
  ${({ open }: { open: boolean }) =>
    open
      ? `background-color: white;
         box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);`
      : ""};
  border-radius: 4px;
  padding: 8px;
`;

const LocaleItem = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 4px;
  & a {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    &:hover {
      text-decoration: none;
    }
  }
  ${({ hidden = false }: { hidden?: boolean }) =>
    hidden
      ? `
    height: 0;
    overflow: hidden;
    opacity: 0;
    margin-top: 0 !important;
    `
      : ""}
`;

const LocaleItemLabel = styled.div`
  flex-grow: 1;
  min-width: 64px;
  font-weight: 600;
`;

const IconWrapper = styled.div`
  margin-left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.color.primary};
  padding: 1px;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);
`;

const icons: { [locale in Locale]: ReactNode } = {
  EN_US: (
    <IconWrapper>
      <EnglishIcon />
    </IconWrapper>
  ),
  EL_GR: (
    <IconWrapper>
      <GreekIcon />
    </IconWrapper>
  ),
};
