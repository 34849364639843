export const RightChevron = ({
  style = { width: "100%", height: "100%" },
}: {
  style?: React.CSSProperties;
}) => (
  <svg style={style} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
  </svg>
);

export const LeftChevron = ({
  style = { width: "100%", height: "100%" },
}: {
  style?: React.CSSProperties;
}) => (
  <svg style={style} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
  </svg>
);

export const ArrowDropUp = () => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M7 14l5-5 5 5z"></path>
  </svg>
);

export const ArrowDropDown = () => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M7 10l5 5 5-5z"></path>
  </svg>
);

export const SearchIcon = () => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
  </svg>
);

export const MenuIcon = ({
  style = { width: 24, height: 24 },
}: {
  style?: React.CSSProperties;
}) => (
  <svg style={style} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
  </svg>
);

export const GreekIcon = () => (
  <svg viewBox="0 0 5120 5120">
    <g fill="rgb(35,125,235)">
      <g>
        <path d="M940 4538 c-170 -134 -460 -464 -545 -620 l-26 -48 2191 0 2192 0 -31 53 c-100 174 -385 496 -548 620 l-61 47 -1554 0 -1553 -1 -65 -51z"></path>
        <path d="M81 3183 c-60 -225 -89 -538 -72 -779 13 -171 40 -346 72 -466 l21 -78 409 0 409 0 0 335 0 335 305 0 305 0 0 -335 0 -335 500 0 500 0 0 -305 0 -305 -500 0 -500 0 0 -514 0 -514 48 -22 c77 -37 299 -107 422 -134 366 -81 754 -81 1120 0 328 72 689 236 939 425 105 79 111 85 111 93 0 3 -355 6 -790 6 l-790 0 0 300 0 300 1065 0 1064 0 58 98 c31 53 82 151 111 217 52 114 142 368 142 400 0 13 -144 15 -1220 15 l-1220 0 0 305 0 305 1260 0 c1068 0 1261 2 1266 14 9 23 -15 321 -37 444 -10 65 -29 152 -40 195 l-21 77 -2458 0 -2458 0 -21 -77z"></path>
        <path d="M395 1203 c56 -105 223 -312 361 -450 78 -79 147 -143 153 -143 8 0 11 98 11 320 l0 320 -276 0 -275 0 26 -47z"></path>
      </g>
    </g>
    <g fill="rgb(240,240,240)">
      <g>
        <path d="M2410 5108 c-439 -21 -889 -171 -1257 -418 -65 -44 -131 -90 -148 -104 l-30 -25 1585 0 1585 0 -30 25 c-65 55 -256 176 -375 238 -419 218 -843 309 -1330 284z"></path>
        <path d="M338 3818 c-64 -114 -101 -191 -142 -291 -38 -91 -96 -263 -96 -284 0 -11 457 -13 2460 -13 2080 0 2460 2 2460 14 0 19 -71 227 -105 306 -35 83 -75 164 -133 268 l-46 82 -2176 0 -2176 0 -46 -82z"></path>
        <path d="M890 2225 l0 -335 -395 0 c-333 0 -395 -2 -395 -14 0 -22 55 -185 95 -281 41 -101 102 -224 153 -312 l36 -63 253 0 253 0 0 -297 0 -298 80 -64 c133 -104 296 -208 445 -283 l140 -70 3 506 2 506 500 0 500 0 0 -330 0 -330 794 0 795 0 63 52 c98 81 250 238 338 349 76 95 180 242 180 254 0 3 -488 5 -1085 5 l-1085 0 0 335 0 335 1234 0 1234 0 21 93 c33 147 51 273 57 397 2 63 7 130 9 148 l4 32 -1280 0 -1279 0 0 -335 0 -335 -500 0 -500 0 0 335 0 335 -335 0 -335 0 0 -335z"></path>
      </g>
    </g>
  </svg>
);

export const EnglishIcon = () => (
  <svg viewBox="0 0 5120 5120">
    <g fill="rgb(216,0,39)">
      <g>
        <path d="M2370 5109 c-58 -4 -120 -11 -137 -14 l-33 -6 -2 -1082 -3 -1082 -1081 -3 -1082 -2 -5 -23 c-19 -73 -28 -346 -17 -494 6 -87 13 -168 17 -180 l5 -23 1082 -2 1081 -3 3 -1081 2 -1082 23 -5 c73 -19 346 -28 494 -17 87 6 168 13 180 17 l23 5 2 1082 3 1081 1081 3 1082 2 5 23 c18 70 27 347 17 494 -6 87 -13 168 -17 180 l-5 23 -1082 2 -1081 3 -3 1082 -2 1081 -28 6 c-43 9 -291 26 -357 25 -33 -1 -107 -5 -165 -10z"></path>
        <path d="M853 4461 c-43 -38 -89 -80 -103 -95 l-24 -27 564 -564 c515 -515 568 -565 597 -565 l33 0 0 183 0 182 -478 478 c-264 264 -485 477 -494 477 -10 0 -53 -31 -95 -69z"></path>
        <path d="M3772 3827 c-512 -512 -562 -565 -562 -595 l0 -32 183 0 182 0 484 484 484 484 -21 29 c-27 38 -172 193 -180 193 -4 0 -260 -253 -570 -563z"></path>
        <path d="M1540 1908 c0 -7 -215 -227 -477 -490 -470 -470 -476 -477 -461 -500 9 -13 53 -63 98 -111 l83 -88 569 568 c518 518 568 571 568 600 l0 33 -190 0 c-145 0 -190 -3 -190 -12z"></path>
        <path d="M3200 1732 l0 -187 484 -484 485 -484 34 26 c19 14 71 61 116 103 l82 77 -568 569 c-517 517 -571 568 -600 568 l-33 0 0 -188z"></path>
      </g>
    </g>
    <g fill="rgb(0,82,180)">
      <g>
        <path d="M1755 4986 c-157 -52 -339 -134 -472 -211 -103 -61 -287 -187 -299 -206 -4 -7 175 -192 432 -450 399 -399 442 -439 471 -439 l33 0 0 675 c0 636 -1 675 -17 675 -10 -1 -76 -20 -148 -44z"></path>
        <path d="M3200 4355 l0 -675 33 0 c29 0 72 40 470 438 240 240 437 440 437 445 0 13 -163 128 -284 200 -66 39 -174 95 -239 125 -120 54 -367 142 -399 142 -17 0 -18 -39 -18 -675z"></path>
        <path d="M494 4064 c-109 -147 -214 -331 -287 -504 -46 -108 -117 -318 -117 -345 0 -13 78 -15 675 -15 l675 0 0 33 c0 29 -40 72 -438 470 -240 240 -440 437 -444 437 -5 0 -33 -34 -64 -76z"></path>
        <path d="M4117 3702 c-396 -397 -437 -440 -437 -470 l0 -32 675 0 c592 0 675 2 675 15 0 32 -90 286 -142 401 -30 66 -86 174 -125 240 -73 123 -187 284 -200 284 -5 0 -205 -197 -446 -438z"></path>
        <path d="M90 1903 c0 -33 88 -280 142 -400 30 -65 86 -173 125 -239 72 -121 187 -284 201 -284 4 0 204 197 445 438 396 397 437 440 437 470 l0 32 -675 0 c-636 0 -675 -1 -675 -17z"></path>
        <path d="M3680 1887 c0 -29 40 -72 438 -470 240 -240 440 -437 445 -437 13 0 128 163 200 284 39 66 95 174 125 239 54 120 142 367 142 400 0 16 -39 17 -675 17 l-675 0 0 -33z"></path>
        <path d="M1417 1002 c-240 -240 -437 -440 -437 -445 0 -13 154 -122 279 -196 67 -41 177 -98 243 -128 120 -55 368 -143 401 -143 16 0 17 39 17 675 l0 675 -33 0 c-29 0 -72 -40 -470 -438z"></path>
        <path d="M3200 765 c0 -636 1 -675 18 -675 32 0 279 88 399 142 65 30 173 86 239 125 121 72 284 187 284 201 0 4 -197 204 -438 445 -397 396 -440 437 -470 437 l-32 0 0 -675z"></path>
      </g>
    </g>
    <g fill="rgb(218,15,51)">
      <g>
        <path d="M923 4523 c-27 -18 -30 -35 -5 -31 9 1 23 -1 30 -5 8 -6 12 0 12 22 0 35 -5 37 -37 14z"></path>
        <path d="M4499 4199 c-8 -5 -10 -9 -5 -9 5 0 3 -7 -4 -15 -11 -13 -8 -15 19 -15 34 0 37 6 18 32 -9 11 -18 14 -28 7z"></path>
        <path d="M1056 1431 c-440 -440 -478 -481 -466 -498 8 -10 16 -20 18 -23 5 -7 962 954 962 967 0 7 7 13 15 13 8 0 15 5 15 10 0 6 -15 10 -33 10 -29 0 -75 -43 -511 -479z"></path>
        <path d="M4170 606 c0 -23 2 -25 20 -14 10 7 18 13 17 13 -1 0 -10 7 -19 14 -16 12 -18 11 -18 -13z"></path>
      </g>
    </g>
    <g fill="rgb(240,240,240)">
      <g>
        <path d="M2125 5079 c-27 -5 -92 -19 -142 -30 l-93 -21 0 -662 0 -661 -442 442 -443 442 -43 -37 -43 -37 486 -485 485 -485 0 -155 0 -155 -568 568 -568 568 -86 -98 c-47 -54 -97 -111 -111 -128 l-26 -30 442 -442 442 -443 -662 0 -661 0 -21 -92 c-24 -110 -41 -205 -41 -230 0 -17 42 -18 1097 -16 l1098 3 3 1098 2 1097 -27 -1 c-16 -1 -50 -5 -78 -10z"></path>
        <path d="M2892 3993 l3 -1098 1098 -3 c1071 -2 1097 -2 1097 17 0 22 -25 156 -46 254 l-16 67 -661 0 -662 0 442 443 442 442 -37 43 -37 43 -485 -486 -485 -485 -155 0 -155 0 568 568 568 568 -98 86 c-54 47 -111 97 -128 111 l-30 26 -442 -442 -443 -442 0 662 0 661 -92 21 c-110 24 -205 41 -230 41 -17 0 -18 -42 -16 -1097z"></path>
        <path d="M30 2212 c0 -25 17 -120 41 -229 l21 -93 661 0 662 0 -442 -442 -442 -443 37 -43 37 -43 485 486 485 485 160 0 160 0 -565 -565 c-311 -311 -566 -569 -568 -573 -2 -8 76 -80 213 -196 l30 -25 443 442 442 442 0 -662 0 -661 93 -21 c109 -24 204 -41 229 -41 17 0 18 42 16 1097 l-3 1098 -1097 3 c-1056 2 -1098 1 -1098 -16z"></path>
        <path d="M2897 2223 c-4 -3 -7 -498 -7 -1100 0 -1034 1 -1093 18 -1093 28 0 131 18 230 41 l92 21 0 661 0 662 443 -442 442 -442 43 37 43 37 -486 485 -485 485 0 160 0 160 565 -565 c311 -311 569 -566 573 -568 7 -2 92 91 196 213 l25 30 -442 443 -442 442 662 0 661 0 21 93 c24 109 41 204 41 229 0 17 -44 18 -1093 18 -602 0 -1097 -3 -1100 -7z"></path>
      </g>
    </g>
  </svg>
);
