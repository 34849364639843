import {
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import config from "../../config";

class ClientManager {
  private _client: ApolloClient<NormalizedCacheObject> | undefined;

  constructor(private auth?: any) {}

  get client(): ApolloClient<NormalizedCacheObject> {
    if (!this._client) this.buildClient();
    return this._client!;
  }

  buildClient() {
    const httpLink = new HttpLink({
      uri: () => (this.auth ? config.api.admin : config.api.public),
      // Hack to avoid pre-flight requests, requires corresponding hack on the backend
      headers: {
        // @ts-ignore
        "content-type": config.contentType ?? "application/json",
      },
    });

    const authMiddleware = setContext((operation, { headers }) =>
      this.auth
        ? this.auth.currentSession().then((session: any) => ({
            headers: {
              ...headers,
              // @ts-ignore
              authorization: session.accessToken.jwtToken,
            },
          }))
        : {}
    );

    this._client = new ApolloClient({
      link: ApolloLink.from([authMiddleware, httpLink]),
      cache: new InMemoryCache({ addTypename: false }),
    });
  }

  useAuth(value: any) {
    this.auth = value;
  }
}

export const clientManager = new ClientManager();
