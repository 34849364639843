import React, { FC, useContext, useMemo } from "react";
import { useMediaQuery } from "react-responsive";

export const MAX_X_SMALL = "(max-width: 600px)"; // same as Mui
export const MAX_SMALL = "(max-width: 960px)"; // same as Mui
export const MAX_MEDIUM = "(max-width: 1050px)"; // fine tuned for our use cases
export const MAX_LARGE = "(max-width: 1215px)"; // fine tuned for our use cases

type ResponsiveValues<T> = {
  xl: T;
  l: T;
  m: T;
  s: T;
  xs: T;
};

type ResponsiveMapper = <T extends unknown>(
  values: Partial<ResponsiveValues<T>> & { xs: T }
) => T;

export const useResponsiveValue = ({
  xl,
  l,
  m,
  s,
  xs,
}: Partial<ResponsiveValues<number>>) => {
  const isXSmall = useMediaQuery({ query: MAX_X_SMALL });
  const isSmall = useMediaQuery({ query: MAX_SMALL });
  const isMedium = useMediaQuery({ query: MAX_MEDIUM });
  const isLarge = useMediaQuery({ query: MAX_LARGE });

  if (xs !== undefined && isXSmall) return xs;
  if (s !== undefined && isSmall) return s;
  if (m !== undefined && isMedium) return m;
  if (l !== undefined && isLarge) return l;
  return xl ?? 0;
};

type ResponsiveContextValue = {
  xSmall: boolean;
  small: boolean;
  medium: boolean;
  large: boolean;
  xLarge: boolean;
  getResponsiveValue: ResponsiveMapper;
};

const ResponsiveContext = React.createContext<ResponsiveContextValue>({
  xSmall: false,
  small: false,
  medium: false,
  large: false,
  xLarge: false,
  // @ts-ignore
  getResponsiveValue: ({ xs: number }) => null,
});

export const useResponsive = () => useContext(ResponsiveContext);

export const ResponsiveProvider: FC = ({ children }) => {
  const isXSmall = useMediaQuery({ query: MAX_X_SMALL });
  const isSmall = useMediaQuery({ query: MAX_SMALL });
  const isMedium = useMediaQuery({ query: MAX_MEDIUM });
  const isLarge = useMediaQuery({ query: MAX_LARGE });

  const value = useMemo(
    () => ({
      xSmall: isXSmall,
      small: isSmall,
      medium: isMedium,
      large: isLarge,
      xLarge: !(isXSmall || isSmall || isMedium || isLarge),
      getResponsiveValue<T>({
        xl,
        l,
        m,
        s,
        xs,
      }: Partial<ResponsiveValues<T>> & { xs: T }): T {
        // Return the largest given values that fits the screen
        if (isXSmall) return xs;
        if (isSmall) return s ?? xs;
        if (isMedium) return m ?? s ?? xs;
        if (isLarge) return l ?? m ?? s ?? xs;
        return xl ?? l ?? m ?? s ?? xs;
      },
    }),
    [isXSmall, isSmall, isMedium, isLarge]
  );

  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  );
};
