import { FC, useEffect, useMemo } from "react";

import { BooksFilters } from "../../common/hooks/use-books";
import { useCategories } from "../../common/providers/categories";
import { usePageMeta } from "../../common/providers/metadata";
import { useResponsive } from "../../common/providers/responsive";
import BookListing from "../components/elements/BookListing";
import FullPageErrorMessage from "../components/elements/FullPageErrorMessage";
import SeoLangRelTag from "../components/SeoLangRelTag";
import { MainHeader, PlaceholderRect } from "../components/styled";

type CategoryPageProps = {
  categoryId: string;
};

const CategoryPage: FC<CategoryPageProps> = ({ categoryId }) => {
  const { setPageMeta } = usePageMeta();
  const { categoriesMap } = useCategories();
  const category = categoriesMap[categoryId];
  const { xSmall } = useResponsive();

  const booksFilter: BooksFilters = useMemo(
    () => ({
      filterCategory: categoryId,
    }),
    [categoryId]
  );

  const categoryTitle =
    category?.superCategory === category?.subCategory
      ? `${category?.superCategory}`
      : `${category?.superCategory} – ${category?.subCategory}`;

  useEffect(
    () => category && setPageMeta({ title: categoryTitle }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoryTitle]
  );

  const returnAction: [string, string] = useMemo(
    () => ["return_category", `{}: ${category?.subCategory}`],
    [category]
  );

  if (Object.keys(categoriesMap).length && !category) {
    // Invalid category
    return <FullPageErrorMessage messageKey="no_category" />;
  }

  return (
    <>
      <SeoLangRelTag />
      {category ? (
        <MainHeader>{categoryTitle}</MainHeader>
      ) : (
        <PlaceholderRect
          width={xSmall ? "95%" : 600}
          height={34}
          mb={16}
          bg={false}
        />
      )}
      <BookListing filter={booksFilter} returnAction={returnAction} />
    </>
  );
};

export default CategoryPage;
