import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { gql, useApolloClient } from "@apollo/client";
import Collapse from "@material-ui/core/Collapse";
import styled from "styled-components";

import { Book } from "../../../common/types";
import { sendEvent } from "../../../common/util/analytics";
import { Button } from "../styled";

export const REQUEST_CONTACT = gql`
  mutation requestContact($contact: ContactRequest!) {
    messageSent: requestContact(contact: $contact)
  }
`;

type ContactFormProps = {
  book?: Book;
  maxWidth?: number;
};

type FieldName = "name" | "email" | "phone" | "address" | "message";

type FormData = {
  [fieldName in FieldName]: string;
};

enum SubmitStatus {
  NotSubmitted,
  Submitting,
  Success,
  Failure,
}

const ContactForm: FC<ContactFormProps> = ({ book, maxWidth = 1000 }) => {
  const client = useApolloClient();
  const { t } = useTranslation("contact_form");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(
    SubmitStatus.NotSubmitted
  );

  const submit = async (formData: FormData) => {
    setSubmitStatus(SubmitStatus.Submitting);
    const reference = book?.id ?? "contact";
    try {
      const { data: { messageSent = false } = {} } = await client.mutate({
        mutation: REQUEST_CONTACT,
        variables: {
          contact: {
            ...formData,
            reference,
          },
        },
      });
      setSubmitStatus(
        messageSent ? SubmitStatus.Success : SubmitStatus.Failure
      );
      sendEvent("contactSubmit", { reference });
    } catch (error) {
      sendEvent("contactSubmitFail", {
        reference,
        error: error.message,
      });
      setSubmitStatus(SubmitStatus.Failure);
      console.error(error);
    }
  };

  if (submitStatus === SubmitStatus.Success) {
    return <SuccessMessage>{t("send_success")}</SuccessMessage>;
  }

  return (
    <form onSubmit={handleSubmit(submit)} style={{ maxWidth }}>
      {submitStatus === SubmitStatus.Failure && (
        <FailureMessage>{t("send_failure")}</FailureMessage>
      )}

      <TextInputField
        name="name"
        label={t("full_name")}
        register={register}
        errors={errors}
        required={t("required_name")}
        autoFocus
      />
      <TextInputField
        name="email"
        label={t("email_address")}
        register={register}
        errors={errors}
        required={t("required_email")}
        pattern={{
          value: /^.{1,}@[^.]{1,}(?:\.\w{2,})+$/,
          message: t("valid_email"),
        }}
      />
      <TextInputField
        name="phone"
        label={t("phone_number")}
        register={register}
        errors={errors}
        pattern={{
          value: /^\s*\+?(\d\s*){6,14}$/,
          message: t("valid_phone"),
        }}
      />
      <TextInputField
        name="address"
        label={t("postal_address")}
        register={register}
        errors={errors}
      />
      <TextInputField
        name="message"
        label={t("message")}
        multiline={true}
        register={register}
        errors={errors}
        required={t("required_message")}
      />
      <FormFooter>
        <Button disabled={submitStatus === SubmitStatus.Submitting}>
          {t("send_message")}
        </Button>
      </FormFooter>
    </form>
  );
};

type TextInputFieldProps = {
  name: FieldName;
  label: string;
  register: any;
  errors: any;
  multiline?: boolean;
  required?: string;
  pattern?: { value: RegExp; message: string };
  autoFocus?: boolean;
};

const TextInputField: FC<TextInputFieldProps> = ({
  name,
  label,
  errors,
  register,
  multiline = false,
  required,
  pattern,
  autoFocus = false,
}) => {
  const InputComponent = multiline ? TextArea : TextInput;
  const error = errors[name];
  return (
    <div>
      <Label htmlFor={name}>{label}</Label>
      {/* @ts-ignore */}
      <InputComponent
        id={name}
        autoFocus={autoFocus}
        style={{ borderColor: error ? "red" : "inherit" }}
        {...register(name, { required, pattern })}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
  );
};

export default ContactForm;

const Label = styled.label`
  display: block;
  margin-top: 20px;
  margin-bottom: 4px;
  font-size: 16px;
`;

const TextInput = styled.input.attrs({ type: "text" })`
  width: 100%;
  height: 32px;
  border: 1px solid
    ${(props: { error?: string }) => (props.error ? "red" : "#888")};
  border-radius: 4px;
  padding: 8px;
`;

const TextArea = styled.textarea.attrs({ type: "text" })`
  width: 100%;
  height: 32px;
  border: 1px solid #777;
  border-radius: 4px;
  padding: 8px;
  resize: vertical;
  min-height: 80px;
`;

const FormFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const SuccessMessage = styled.div`
  margin-top: 16px;
  border: solid 2px #73ab84;
  padding: 24px;
  border-radius: 4px;
  font-weight: 600;
`;

const FailureMessage = styled.div`
  border: solid 2px red;
  padding: 24px;
  border-radius: 4px;
  font-weight: 600;
`;

type EntryAreaProps = {
  active: boolean;
};

export const EntryArea: FC<EntryAreaProps> = ({ active, children }) => {
  const [activated, setActivated] = useState(false);
  const container = useRef<any>();

  useEffect(() => {
    if (active) {
      setTimeout(() => setActivated(true));
      setTimeout(
        () => window.scrollTo(0, container.current?.offsetTop! - 80),
        150
      );
    }
  }, [active]);

  return (
    <Collapse ref={container} in={activated} timeout={150}>
      {active ? children : null}
    </Collapse>
  );
};
