import { Dictionary } from "../types";

export type PageState<T> = {
  contents: T[];
  number: number;
  pending: boolean;
};

type PaginatedResultIdsResolver = (
  from: number,
  until: number
) => Promise<string[]>;
type PaginatedResultItemsResolver<T> = (ids: string[]) => Promise<T[]>;
type PaginationUpdateListener<T> = (pageState: PageState<T>) => void;

export default class PaginatedResult<T> {
  public static pendingPlaceholder = new PaginatedResult<any>(
    () => new Promise(() => {}),
    () => new Promise(() => {})
  );

  private readonly idsResolver: PaginatedResultIdsResolver;
  private resolvedIds: string[] = [];
  private page: T[] = [];
  private pending: Promise<any> | undefined;
  private readonly listeners: PaginationUpdateListener<T>[] = [];

  static fromItems<T extends { id: string }>(
    items: T[],
    pageSize: number = 20,
    pageNumber: number = 0
  ): PaginatedResult<T> {
    const itemIds = items.map(({ id }) => id);
    const itemDict: Dictionary<T> = items.reduce(
      (result: Dictionary<T>, item: T) => {
        result[item.id] = item;
        return result;
      },
      {}
    );
    return new PaginatedResult<T>(
      async (from: number, until: number) => itemIds.slice(from, until),
      async (ids: string[]) => ids.map((id) => itemDict[id]),
      pageNumber,
      pageSize,
      items.length
    );
  }

  constructor(
    itemIds: PaginatedResultIdsResolver | string[],
    private readonly itemsResolver: PaginatedResultItemsResolver<T>,
    private pageNumber: number = 0,
    public pageSize: number = 20,
    public readonly numItmes: number = -1 // TODO: make this non-optional?
  ) {
    this.idsResolver = Array.isArray(itemIds)
      ? (from, until) => Promise.resolve(itemIds.slice(from, until))
      : itemIds;
    this.setPage(pageNumber);
  }

  get numPages() {
    return Math.ceil(Math.max(0, this.numItmes) / this.pageSize);
  }

  get activePageIndex(): number {
    return this.pageNumber;
  }

  isPending() {
    return !!this.pending;
  }

  onUpdate(listener: PaginationUpdateListener<T>) {
    this.listeners.push(listener);
    return () => {
      const listenerIndex = this.listeners.indexOf(listener);
      if (listenerIndex >= 0) {
        this.listeners.splice(listenerIndex, 1);
      }
    };
  }

  private notifyListeners() {
    const pageState = this.getPage();
    this.listeners.forEach((listener) => listener(pageState));
  }

  getPage(): PageState<T> {
    return {
      contents: this.page,
      number: this.pageNumber,
      pending: !!this.pending,
    };
  }

  async setPage(
    pageNumber: number,
    pageSize: number = this.pageSize
  ): Promise<PageState<T>> {
    const done = this.setPending();
    const maxPageNumber =
      this.numItmes < 0 ? 0 : Math.ceil(this.numItmes / pageSize);
    pageNumber = Math.min(Math.max(0, pageNumber), maxPageNumber);
    const startIndex = pageNumber * pageSize;
    const endIndex = startIndex + pageSize;

    try {
      // Resolve more item ids if needed
      if (this.resolvedIds.length < endIndex) {
        const newlyResolvedIds = await this.idsResolver(
          this.resolvedIds.length,
          endIndex
        );
        newlyResolvedIds.forEach((id) => this.resolvedIds.push(id));
      }

      this.page = await this.itemsResolver(
        this.resolvedIds.slice(startIndex, endIndex)
      );
      this.pageNumber = pageNumber;
      this.pageSize = pageSize;
    } catch (error) {
      console.error("SetPage failed:", error);
    } finally {
      done();
    }

    this.notifyListeners();

    return this.getPage();
  }

  private setPending() {
    let done: Function = () => {};
    const pendingPromise = new Promise((reslv) => (done = reslv));
    if (this.pending) {
      this.pending = this.pending.then(
        () => pendingPromise,
        () => pendingPromise
      );
    } else {
      this.pending = pendingPromise;
      this.notifyListeners();
    }

    // Clear this.pending upon completion IFF this is the latest pending task
    const thisPending = this.pending;
    this.pending.finally(() => {
      if ((this.pending = thisPending)) {
        this.pending = undefined;
      }
      // Notify listeners
      this.notifyListeners();
    });
    return done;
  }
}
