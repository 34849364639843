import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SearchIcon } from "../../../common/components/icons";
import routes from "../../../routes";

const SearchBox: FC<{ fullWidth?: boolean }> = ({ fullWidth = false }) => {
  const { t } = useTranslation("header");
  const [query, setQuery] = useState("");
  const history = useHistory();

  const doSearch = (event: any) => {
    event.preventDefault();
    history.push({
      pathname: routes.to("search-results"),
      search: `?q=${query.trim()}`,
    });
  };

  return (
    <form role="search" style={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
      <SearchInput
        type="search"
        placeholder={t("search_placeholder")}
        value={query}
        onChange={(event) => setQuery(event.target.value)}
        fullWidth={fullWidth}
      />
      <SearchSubmit type="submit" onClick={doSearch} aria-label={t("search_placeholder")}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </SearchSubmit>
    </form>
  );
};

const SearchIconWrapper = styled.span`
  display: block;
  opacity: 0.9;
  width: 22px;
  height: 22px;
  & > svg {
    fill: white;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  margin: 0;
  flex-grow: 1;
  min-width: 100px;
  ${({ fullWidth }: { fullWidth: boolean }) =>
    fullWidth ? "" : "max-width: 170px;"}
  height: 32px;
  -webkit-appearance: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #999;
  padding-left: 16px;
  padding-right: 8px;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%),
    inset 0 12px 11px -2px rgb(0 0 0 / 18%);
  background-color: white;
`;

const SearchSubmit = styled.button`
  margin: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: linear-gradient(
    180deg,
    rgb(88 140 213),
    rgba(20, 99, 200, 1) 80%
  );
  padding: 4px 16px;
  cursor: pointer;
  color: #eee;
  font-weight: 600;
  background-color: ${({ theme }) => theme.color.primary};
  border: none;
  height: 32px;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);
`;

export default SearchBox;
