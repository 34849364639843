import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import BookImage from "../../../common/components/BookImage";
import { useFeaturedBooks } from "../../../common/providers/featured";
import { sendEvent } from "../../../common/util/analytics";
import { PageState } from "../../../common/util/PaginatedResult";
import {
  MAX_X_SMALL,
  useResponsive,
} from "../../../common/providers/responsive";
import { Book } from "../../../common/types";
import routes from "../../../routes";
import { PlaceholderRect, Spacer, SubHeader } from "../styled";

const FeaturedBooks = () => {
  // TODO: handle error? "Failed to load content"

  const { t } = useTranslation("homepage");
  const { getResponsiveValue } = useResponsive();
  const pageSize = getResponsiveValue({ xl: 10, l: 8, xs: 6 });
  const {
    loading,
    featuredBooks: books,
    initFeaturedBooks,
  } = useFeaturedBooks();

  const [{ contents: pageItems, pending }, setPageState] = useState<
    PageState<Book>
  >(() => books.getPage());

  useEffect(() => {
    initFeaturedBooks(pageSize);
  }, [initFeaturedBooks, pageSize]);

  useEffect(() => {
    setPageState(books.getPage());
    books.onUpdate(setPageState);
  }, [books]);

  if ((loading || pending) && !pageItems.length) {
    return renderPlaceholder(pageSize, t);
  }

  return (
    <section>
      <Spacer size={24} />
      <SubHeader>{t("featured")}</SubHeader>
      <FeaturedBooksGrid>
        {pageItems.map((book) => (
          <BookImageWrapper key={book.id}>
            <Link
              to={{
                pathname: routes.to("book-details", {
                  bookId: book.id,
                }),
              }}
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "block",
                width: "100%",
              }}
              onClick={() => sendEvent("clickFeatured", { bookId: book.id })}
            >
              <BookImage image={book.images?.[0]} alt={book.title} />
            </Link>
          </BookImageWrapper>
        ))}
      </FeaturedBooksGrid>
    </section>
  );
};

export default FeaturedBooks;

const renderPlaceholder = (pageSize: number, t: (key: string) => string) => (
  <section>
    <Spacer size={16} />
    <SubHeader>{t("featured")}</SubHeader>
    <FeaturedBooksGrid>
      {range(pageSize).map((index) => (
        <BookImageWrapper key={index}>
          <PlaceholderRect width={110} height={150} bg={false} />
        </BookImageWrapper>
      ))}
    </FeaturedBooksGrid>
  </section>
);

const FeaturedBooksGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const BookImageWrapper = styled.div`
  min-width: 135px;
  max-width: 135px;
  min-height: 150px;
  max-height: 150px;
  text-align: center;
  align-items: center;
  margin: 32px 16px;
  & img {
    max-width: 135px;
    max-height: 150px;
    transition: transform 180ms ease, box-shadow 180ms ease;
  }
  & :hover {
    & img {
      transform: scale3d(1.4, 1.4, 1.4);
      box-shadow: 0 2px 5px rgb(0 0 0 / 40%);
    }
  }
  @media ${MAX_X_SMALL} {
    margin: 20px 12px;
  }
`;

const range = (end: number) => {
  const result = [];
  for (let i = 0; i < end; i++) result.push(i);
  return result;
};
