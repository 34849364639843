import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

import {
  useResponsive,
  MAX_X_SMALL,
} from "../../../common/providers/responsive";
import { OnClickHandler } from "../../../common/types";
import routes from "../../../routes";

import wpheaderBooks from "../../images/header_books.webp";
import wpheaderPaper from "../../images/header_paper.webp";
import wpheaderShelf from "../../images/header_shelf.webp";
import wptitleBook from "../../images/title_book.webp";
import wpheaderBooksXs from "../../images/header_books_xs.webp";
import wpheaderPaperXs from "../../images/header_paper_xs.webp";
import wpheaderShelfXs from "../../images/header_shelf_xs.webp";

import headerBooks from "../../images/header_books.png";
import headerPaper from "../../images/header_paper.jpg";
import headerShelf from "../../images/header_shelf.jpg";
import titleBook from "../../images/title_book.png";
import headerBooksXs from "../../images/header_books_xs.png";
import headerPaperXs from "../../images/header_paper_xs.jpg";
import headerShelfXs from "../../images/header_shelf_xs.jpg";

import TopBar from "./TopBar";

const useScrollInterpolation = (start: number, end: number) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const listener = (event: any) => {
      setValue(() =>
        Math.max(0, Math.min(1, (window.scrollY - start) / (end - start)))
      );
    };

    document.addEventListener("scroll", listener);

    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [start, end]);

  return value;
};

const PageHeader: FC<{ onClickMenuBtn: OnClickHandler }> = ({
  onClickMenuBtn,
}) => {
  const { t } = useTranslation("header");
  const { xSmall } = useResponsive();
  let interpolation = useScrollInterpolation(0, 100);
  if (xSmall) {
    interpolation = 1;
  }

  return (
    <>
      <HeaderWrapper
        style={{
          backgroundPositionY: -5 - interpolation * 100,
        }}
      >
        <TopBarWrapper>
          <TopBar
            interpolation={interpolation}
            onClickMenuBtn={onClickMenuBtn}
          />
        </TopBarWrapper>
        <Container>
          {xSmall ? null : (
            <TitleDecoration>
              <div
                style={{
                  top: 125 - interpolation * 100,
                  transform: `rotate3d(0,0,1,-${90 * interpolation}deg) `,
                }}
              />
            </TitleDecoration>
          )}
          <HeaderBackground style={{ height: 164 - interpolation * 100 }} />
        </Container>
        <SubNavContainer>
          <Container style={{ height: "100%" }}>
            <SubNavList>
              <div style={{ flexGrow: 1 }}></div>
              <SubNavItem style={{ marginLeft: 0 }}>
                <Link to={routes.to("authors-index")}>{t("authors")}</Link>
              </SubNavItem>
              <SubNavItemDivider />
              <SubNavItem>
                <Link to={routes.to("keywords-index")}>{t("keywords")}</Link>
              </SubNavItem>
              <SubNavItemDivider />
              <SubNavItem>
                <Link to={routes.to("contact")}>{t("contact")}</Link>
              </SubNavItem>
            </SubNavList>
          </Container>
        </SubNavContainer>
      </HeaderWrapper>
      <div style={{ height: xSmall ? 124 : 232 }} />
    </>
  );
};

const HeaderWrapper = styled.header`
  position: fixed;
  width: 100vw;
  background-image: url(${({ theme }) =>
    theme.webp ? wpheaderPaper : headerPaper});
  background-repeat-x: repeat;
  background-color: ${({ theme }) => theme.color.feature};
  z-index: 50;
  > .MuiContainer-root {
    padding: 0;
  }
  @media ${MAX_X_SMALL} {
    background-image: url(${({ theme }) =>
      theme.webp ? wpheaderPaperXs : headerPaperXs});
    background-position-y: top !important;
  }
`;

const TopBarWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 100;
`;

const TitleDecoration = styled.div`
  padding-left: 30px; /* Compensating for removed container padding */
  > div {
    position: absolute;
    background-image: url(${({ theme }) =>
      theme.webp ? wptitleBook : titleBook});
    background-repeat: no-repeat;
    transform-origin: bottom left;
    width: 161px;
    height: 38px;
  }
`;

const HeaderBackground = styled.div`
  background-image: url(${({ theme }) =>
    theme.webp ? wpheaderBooks : headerBooks});
  background-position-x: 314px;
  background-position-y: bottom;
  background-repeat: no-repeat;
  @media ${MAX_X_SMALL} {
    background-image: url(${({ theme }) =>
      theme.webp ? wpheaderBooksXs : headerBooksXs});
    background-position-x: 300px;
  }
`;

const SubNavContainer = styled.nav`
  width: 100%;
  height: 34px;
  background-image: url(${({ theme }) =>
    theme.webp ? wpheaderShelf : headerShelf});
  background-position-x: -50%;
  background-repeat-x: repeat;
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.7);
  @media ${MAX_X_SMALL} {
    background-image: url(${({ theme }) =>
      theme.webp ? wpheaderShelfXs : headerShelfXs});
    background-position-x: 0;
    font-size: 13px;
  }
`;

const SubNavList = styled.nav`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -12px;
  height: 100%;
`;

const SubNavItemDivider = styled.div`
  &:before {
    font-size: 0.8em;
    content: "•";
  }
  display: flex;
  padding-top: 10px;
  margin: 0 8px;
  @media ${MAX_X_SMALL} {
    margin: 0 6px;
  }
`;

const SubNavItem = styled.div`
  height: 100%;
  line-height: 1.1em;
  text-align: center;
  display: flex;
  align-items: center;
  & > a {
    font-weight: bold;
    text-decoration: none;
    color: inherit;
  }
  & > a:hover {
    text-shadow: 1px 2px 4px #fff7ef;
  }
`;

export default PageHeader;
