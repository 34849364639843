import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import config from "../../config";
import { Image } from "../../common/types";
import { PlaceholderRectProps } from "../../website/components/styled";

type BookImageProps = {
  image: Image | undefined;
  alt?: string;
  title?: string;
  className?: string;
  placeholder?: PlaceholderRectProps;
};

const Img = styled.img``;

const BookImage: FC<BookImageProps> = ({
  image,
  alt,
  title,
  className,
  placeholder,
}) => {
  const { t } = useTranslation("elements");
  const [hasError, setHasError] = useState(false);

  if ((!image || hasError) && placeholder) {
    return (
      <ImageErrorPlaceholder {...placeholder}>
        {t("book_image_missing")}
      </ImageErrorPlaceholder>
    );
  }

  if (image) {
    return (
      <Img
        alt={alt}
        title={title ?? alt}
        className={className}
        src={`${config.cdn}/img/${image.bookId}/${image.number}${image.extension}`}
        onError={() => setHasError(true)}
      />
    );
  }
  return null;
};

export default BookImage;

const ImageErrorPlaceholder = styled.div`
  background-color: ${({ theme }) => "#ece3d3"};
  width: ${({ width }: PlaceholderRectProps) =>
    `${width}${typeof width === "number" ? "px" : ""}`};
  height: ${({ height }: PlaceholderRectProps) =>
    `${height}${typeof height === "number" ? "px" : ""}`};
  margin: ${({ mt = 0, mr = 0, mb = 0, ml = 0 }: PlaceholderRectProps) =>
    `${mt}px ${mr}px ${mb}px ${ml}${typeof ml === "number" ? "px" : ""}`};
  align-items: center;
  padding: 15% 5%;
  font-size: ${({ fontSize }: PlaceholderRectProps) =>
    `${fontSize ? fontSize + "px" : "1.3em"}`};
`;
