import Plausible from "plausible-tracker";

const { trackEvent, enableAutoPageviews } = Plausible({
  domain: "retsasbooks.gr",
  hashMode: true,
});

enableAutoPageviews();

export function sendEvent(label: string, metadata?: any) {
  try {
    if (metadata) {
      trackEvent(label, { props: metadata });
    } else {
      trackEvent(label);
    }
  } catch {}
}
