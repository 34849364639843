import { FC, useMemo, useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";

import routes from "../../../routes";
import { useCategories } from "../../../common/providers/categories";
import { PlaceholderRect, SubHeader } from "../styled";

type SubCategory = {
  id: string;
  name: string;
};

type SuperCategory = {
  name: string;
  defaultOpen: boolean;
  subCategories: SubCategory[];
};

const CategoriesList: FC = () => {
  const { t } = useTranslation("elements");
  const {
    // @ts-ignore
    params: { categoryId: categoryIdParam } = {},
  } = useRouteMatch(routes.get("category-books")) ?? {};
  const { categories, loading } = useCategories();

  const content: SuperCategory[] = useMemo(() => {
    const groupedCats = categories?.reduce(
      (result: { [id: string]: SuperCategory }, category) => {
        if (category.size === 0) {
          // Hide empty categories
          return result;
        }
        if (category.superCategory in result) {
          result[category.superCategory].subCategories.push({
            id: category.id,
            name: category.subCategory,
          });
          if (categoryIdParam === category.id) {
            result[category.superCategory].defaultOpen = true;
          }
        } else {
          result[category.superCategory] = {
            name: category.superCategory,
            defaultOpen: categoryIdParam === category.id,
            subCategories: [
              {
                id: category.id,
                name: category.subCategory,
              },
            ],
          };
        }
        return result;
      },
      {}
    );

    return Object.values(groupedCats);
  }, [categories, categoryIdParam]);

  return (
    <div>
      <SubHeader>{t("categories_header")}</SubHeader>
      {loading && !categories.length
        ? renderPlaceholder()
        : content.map((props) => (
            <CategoriesListItem key={props.name} {...props} />
          ))}
    </div>
  );
};

const renderPlaceholder = () => (
  <div>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={165} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={175} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={185} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={165} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={155} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={175} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={185} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={165} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={175} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
    <CategoryGroup>
      <SuperCategoryLabel>
        <PlaceholderRect width={185} height={18} />
      </SuperCategoryLabel>
    </CategoryGroup>
  </div>
);

export default CategoriesList;

const CategoriesListItem: FC<{
  name: string;
  subCategories: SubCategory[];
  defaultOpen: boolean;
}> = ({ name, subCategories, defaultOpen }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <CategoryGroup>
      <SuperCategoryLabel
        onClick={() => setOpen(!open)}
        style={{ fontWeight: open ? "bold" : "normal" }}
      >
        {name}
      </SuperCategoryLabel>
      <Collapse in={open} style={{ marginRight: -24, marginLeft: -4 }}>
        <SubCategoryList>
          {subCategories.map(({ id, name }) => (
            <SubCategoryListItem key={id}>
              <NavLink
                to={routes.to("category-books", { categoryId: id })}
                activeStyle={{ fontWeight: "bold" }}
              >
                {name}
              </NavLink>
            </SubCategoryListItem>
          ))}
        </SubCategoryList>
      </Collapse>
    </CategoryGroup>
  );
};

const CategoryGroup = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  border-bottom: 1px solid #d8d8d8;
  font-size: 13px;
  &:last-child {
    border-bottom: none;
  }
`;

const SuperCategoryLabel = styled.div`
  height: 48px;
  width: 100%;
  margin-left: -24px;
  display: flex;
  user-select: none;
  cursor: pointer;
  align-items: center;
  & a {
    font-weight: normal;
  }
`;

const SubCategoryList = styled.ul`
  margin-right: -16px;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
`;

const SubCategoryListItem = styled.li`
  padding-left: 4px;
  margin-bottom: 16px;
  > a {
    text-decoration: none;
    color: inherit;
    font-weight: normal;
  }
`;
