import { ulid } from "ulid";

export type Locale = "EL_GR" | "EN_US";

export interface Author {
  nativeName: string;
  greekName?: string;
}

export const formatAuthor = (author: Author): string => {
  if (author.greekName) {
    return `${author.greekName} (${author.nativeName})`;
  } else {
    return author.nativeName;
  }
};

export interface Image {
  bookId: string;
  number: number;
  extension: string;
  uploadUrl?: string;
  toBeDeleted?: boolean;
}

interface BookLike {
  id: string;
  title: string;
  authors: Author[];
  authorship?: string;
  keywords: string[];
  price: number;
  description: string;
  featured: boolean;
  hidden: boolean;
  available: number;
  illustration: string;
  translator: string;
  edition: string;
  volumes: string;
  pages: string;
  publisher: string;
  place: string;
  year: string;
  bookNumber: string;
  dedication: string;
  views?: number;
  categoryIds?: string[];
  location: string;
  lockVersion?: number;
  createdAt?: number;
  updatedAt?: number;
}

export interface Book extends BookLike {
  images?: Image[];
}

export interface BookInput extends BookLike {
  images: number[];
}

export const newBook = (): Book => ({
  id: ulid().slice(5, 20),
  title: "",
  authors: [],
  authorship: "",
  keywords: [],
  images: [],
  price: 0,
  description: "",
  featured: false,
  hidden: false,
  available: 1,
  illustration: "",
  translator: "",
  edition: "",
  volumes: "",
  pages: "",
  publisher: "",
  place: "",
  year: "",
  bookNumber: "",
  dedication: "",
  location: "",
  views: 0,
  categoryIds: [],
  lockVersion: 0,
});

type BookValidation = {
  title?: string;
  price?: string;
  available?: string;
  authors?: string;
  categories?: string;
  hasErrors: boolean;
};

export const validateBook = (
  book: BookInput,
  t: (str: string) => string
): BookValidation => {
  const result: BookValidation = { hasErrors: false };

  // title
  if (!book.title || !book.title.trim()) {
    result.title = t("validation.titleRequired");
    result.hasErrors = true;
  }

  // price
  if (
    typeof book.price !== "number" ||
    book.price <= 0 ||
    isNaN(book.price) ||
    !isFinite(book.price)
  ) {
    result.price = t("validation.nativeNameValidity");
    result.hasErrors = true;
  }

  // available
  if (
    typeof book.available !== "number" ||
    book.available < 0 ||
    isNaN(book.available) ||
    !isFinite(book.available)
  ) {
    result.available = t("validation.availableIsNum");
    result.hasErrors = true;
  }

  // authors
  if (!book.authors) {
    result.authors = t("validation.authorsRequired");
    result.hasErrors = true;
  } else if (
    book.authors.some(
      ({ nativeName, greekName }) => !nativeName || !nativeName.trim()
    )
  ) {
    result.authors = t("validation.nativeNameRequired");
    result.hasErrors = true;
  }

  // categories
  if (!book.categoryIds || book.categoryIds.length < 1) {
    result.categories = t("validation.catRequired");
    result.hasErrors = true;
  }

  return result;
};

export interface Category {
  id: string;
  locale: Locale;
  superCategory: string;
  subCategory: string;
  displayOrder: number;
  lockVersion?: number;
  size?: number;
}

export type SortOrder = "ASC" | "DESC";

export interface Dictionary<T> {
  [key: string]: T;
}

export interface SectionDetails {
  locale: Locale;
  title: string;
  content: string;
  lockVersion: number;
}

export interface Section extends SectionDetails {
  id: string;
  updatedAt?: number;
}

export interface SectionUpdate {
  id: string;
  localizations: SectionDetails[];
}

export type OnClickHandler = (event: React.MouseEvent<HTMLElement>) => void;
