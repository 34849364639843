import {
  FC,
  useEffect,
  useRef,
  useState,
  FormEventHandler,
  KeyboardEventHandler,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { PlaceholderRect } from "../styled";
import { useAuthorsKeywords } from "../../../common/providers/authorskeywords";
import routes from "../../../routes";

type KeywordsLookupProps = {
  selected: string;
  maxItems?: number;
  onError?: (error: Error | undefined) => void;
};

function getMatchesSorter(query: string) {
  return function compareMatches(a: string, b: string): number {
    if (a.toUpperCase().startsWith(query)) {
      if (b.toUpperCase().startsWith(query)) {
        return a < b ? -1 : a === b ? 0 : 1;
      }
      return -1;
    } else if (b.toUpperCase().startsWith(query)) {
      return 1;
    } else {
      return a < b ? -1 : a === b ? 0 : 1;
    }
  };
}

const KeywordsLookup: FC<KeywordsLookupProps> = ({
  selected,
  maxItems = 12,
  onError,
}) => {
  const { t } = useTranslation("elements");
  const history = useHistory();
  const {
    keywords: { keywords, loading, error },
  } = useAuthorsKeywords({ keywords: true });
  const [query, setQuery] = useState("");
  const [matches, setMatches] = useState<string[]>([]);
  const [focused, setFocused] = useState(false);
  const [highlighted, setHighlighted] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const upperQuery = query.toUpperCase().trim();
    setMatches(
      (keywords ?? [])
        .filter((keyword) => keyword.toUpperCase().includes(upperQuery))
        .sort(getMatchesSorter(upperQuery))
        .slice(0, maxItems)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, keywords]);

  useEffect(() => {
    setQuery(selected ?? "");
    inputRef.current?.blur();
  }, [selected]);

  useEffect(() => {
    onError?.(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (
      matches.length &&
      matches[0].trim().toUpperCase() === query.trim().toUpperCase() &&
      highlighted !== matches[0]
    ) {
      setHighlighted(matches[0]);
    } else if (matches.length === 1 && highlighted !== matches[0]) {
      setHighlighted(matches[0]);
    } else if (matches.length === 0) {
      setHighlighted("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches.length, query]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = ({
    keyCode,
  }) => {
    switch (keyCode) {
      case 38: // ArrowUp
        {
          const index = matches.indexOf(highlighted);
          if (index > 0) {
            setHighlighted(matches[index - 1]);
          } else if (!highlighted && matches.length) {
            setHighlighted(matches[0]);
          }
        }
        return;
      case 40: // ArrowDown
        {
          const index = matches.indexOf(highlighted);
          if (index < matches.length - 1) {
            setHighlighted(matches[index + 1]);
          } else if (!highlighted && matches.length) {
            setHighlighted(matches[0]);
          }
        }
        return;
      case 27: // Escape
        inputRef.current?.blur();
        return;
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    inputRef.current?.blur();
    if (!highlighted) return;
    setQuery(highlighted);
    history.push(routes.to("keyword-books", { keyword: highlighted }));
  };

  const menuOpen = focused && query.trim().length > 1;

  if (loading) {
    return <PlaceholderRect width="100%" height={32} bg={false} />;
  }

  return (
    <div>
      <KeywordsLookupForm onSubmit={handleSubmit} focused={menuOpen}>
        <KeywordsLookupInput
          ref={inputRef}
          type="text"
          placeholder={t("keywords_lookup_placeholder")}
          value={query}
          onKeyDown={handleKeyDown}
          onChange={({ target }) => setQuery(target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);
            setHighlighted("");
          }}
          dropMenu={menuOpen}
        />
        {menuOpen && (
          <KeywordsLookupItems
            options={matches}
            selected={selected}
            highlighted={highlighted}
            setHighlighted={setHighlighted}
          />
        )}
      </KeywordsLookupForm>
    </div>
  );
};

const KeywordsLookupItems = ({
  options,
  selected,
  highlighted,
  setHighlighted,
}: {
  options: string[];
  selected: string;
  highlighted: string;
  setHighlighted: (keyword: string) => void;
}) => {
  const { t } = useTranslation("elements");

  return (
    <div style={{ height: 0 }}>
      <KeywordsLookupList>
        {options.length ? (
          options.map((keyword, i) => (
            <KeywordsLookupItem
              key={i}
              selected={keyword === selected}
              highlighted={keyword === highlighted}
              onMouseEnter={() => setHighlighted(keyword)}
              onMouseDown={(event: any) => event.preventDefault()}
            >
              <Link to={routes.to("keyword-books", { keyword })}>
                {keyword}
              </Link>
            </KeywordsLookupItem>
          ))
        ) : (
          <KeywordsLookupItem>
            <KeywordsLookupPlaceholderText>
              {t("keywords_lookup_no_matches")}
            </KeywordsLookupPlaceholderText>
          </KeywordsLookupItem>
        )}
      </KeywordsLookupList>
    </div>
  );
};

const KeywordsLookupForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ focused }: { focused?: boolean }) =>
    focused ? "outline: -webkit-focus-ring-color auto 1px;" : ""}
`;

const KeywordsLookupInput = styled.input`
  margin: 0;
  flex-grow: 1;
  min-width: 100px;
  height: 32px;
  -webkit-appearance: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  ${({ dropMenu }: { dropMenu?: boolean }) =>
    !dropMenu
      ? `
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        `
      : ""};
  border: 1px solid #999;
  padding-left: 16px;
  padding-right: 8px;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%),
    inset 0 6px 5px -2px rgb(0 0 0 / 15%);
  background-color: white;
  &:focus {
    outline: none;
  }
`;

const KeywordsLookupList = styled.ul`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
  border-bottom: 1px solid #999;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);
`;

type KeywordsLookupItemProps = {
  highlighted?: boolean;
  selected?: boolean;
};

const KeywordsLookupItem = styled.li`
  list-style: none;
  padding-right: 6px;
  background-color: ${({ highlighted }: KeywordsLookupItemProps) =>
    highlighted ? "#e8e8e8" : "#fff"};
  font-weight: ${({ selected }: KeywordsLookupItemProps) =>
    selected ? "bold" : ""};

  border-bottom: solid 1px #ccc;
  &:last-child {
    border-bottom: none;
  }
  & > a {
    display: block;
    width: 100%;
    padding: 6px 12px 5px 12px;
    &:hover {
      text-decoration: none;
    }
  }
`;

const KeywordsLookupPlaceholderText = styled.span`
  display: block;
  padding: 6px 12px 5px 12px;
  color: #666;
`;

export default KeywordsLookup;
