import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useLocation } from "react-router-dom";

import routes from "../../routes";
import { useResponsive } from "../../common/providers/responsive";
import BookListing from "../components/elements/BookListing";
import FullPageErrorMessage from "../components/elements/FullPageErrorMessage";
import KeywordsLookup from "../components/elements/KeywordsLookup";
import { MainHeader } from "../components/styled";

const KeywordsPage: FC = () => {
  const { t } = useTranslation("elements");
  const location = useLocation();
  const keyword = routes.match(location.pathname)[0]?.params.keyword;
  const [keywordsError, setKeywordsError] = useState<Error>();

  const { getResponsiveValue } = useResponsive();
  const maxItems = getResponsiveValue({
    l: 16,
    s: 12,
    xs: 10,
  });

  if (keywordsError) {
    return <FullPageErrorMessage />;
  }

  return (
    <div>
      <MainHeader>{t("keywords_header")}</MainHeader>
      <KeywordsLookup
        selected={keyword}
        maxItems={maxItems}
        onError={setKeywordsError}
      />
      <Route
        path={routes.get("keyword-books")}
        render={() => <KeywordBooksList keyword={keyword} />}
      />
    </div>
  );
};

const KeywordBooksList = ({ keyword }: { keyword: string }) => {
  const returnAction: [string, string] = useMemo(
    () => ["return_keyword", `{}: ${keyword}`],
    [keyword]
  );

  return (
    <div style={{ marginTop: 16 }}>
      <BookListing
        key={keyword}
        filter={{ keyword }}
        returnAction={returnAction}
        errorKey="no_keyword"
      />
    </div>
  );
};

export default KeywordsPage;
