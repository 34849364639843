import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type MetaUpdates = { title: string; description?: string };

type PageMetaContextValue = {
  setPageMeta: (updates: MetaUpdates) => () => void;
};

const PageMetaContext = React.createContext<PageMetaContextValue>({
  setPageMeta: () => () => {},
});

export const usePageMeta = () => useContext(PageMetaContext);

export const PageMetaProvider: FC = ({ children }) => {
  const { i18n, t } = useTranslation("header");
  const [hasOverride, setHasOverride] = useState(false);

  const nodeRefs = useMemo(
    () => ({
      title: document.getElementsByTagName("title")[0],
      description: Array.from(document.getElementsByTagName("meta")).find(
        (tag) => tag.name === "description"
      ),
    }),
    []
  );

  useEffect(() => {
    if (!hasOverride) {
      nodeRefs.title.text = t("title");
      nodeRefs.description && (nodeRefs.description.content = t("description"));
    }
  }, [hasOverride, i18n.language, nodeRefs, t]);

  const value = useMemo(
    () => ({
      setPageMeta: ({ title, description }: MetaUpdates) => {
        setTimeout(() => {
          if (title === "Admin") {
            nodeRefs.title.text = `${title}: ${t("title")}`;
          } else {
            nodeRefs.title.text = `${t("title")}: ${title}`;
          }
          nodeRefs.description &&
            (nodeRefs.description.content = description ?? t("description"));
          setHasOverride(true);
        });
        // Note: there's a possible race condition here, but should be fine
        return () => setHasOverride(false);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <PageMetaContext.Provider value={value}>
      {children}
    </PageMetaContext.Provider>
  );
};
